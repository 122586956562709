import Link from "next/link";

import { FC } from "react";

import { GradientButton, InfoBlock } from "@causevest/ui-kit";
import { Dialog, Stack } from "@mui/material";

import { AppRoutes } from "@lib/types";

import { AuditItem } from "./AuditItem";
import classes from "./AuditTrailPopup.module.scss";

const STUB_DATA = [
  {
    title: "Clothe the children in Africa",
    avatar: "/images/commentor-1.png",
    date: "10/20/19",
    icon: "/images/heart-gradient.svg",
    event: <Link href={AppRoutes.CAMPAIGNS}>Image Upload</Link>,
  },
  {
    title: "Verified Account",
    avatar: "/images/commentor-2.png",
    date: "10/20/19",
    icon: "/images/usd-gradient.svg",
    event: "Withdrawl",
  },
  {
    title: "Ice Bucket Challange",
    avatar: "/images/commentor-2.png",
    date: "10/20/19",
    icon: "/images/usd-green.svg",
    event: "Donation Recieved",
  },
  {
    title: "Verified Account 2",
    avatar: "/images/logo-short.svg",
    date: "10/20/19",
    icon: "/images/usd-green.svg",
    event: "March Leaderboard Boost",
  },
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AuditTrailPopup: FC<Props> = ({ isOpen, onClose }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <InfoBlock title="Audit Trail">
      <Stack gap="20px">
        {STUB_DATA.map((item) => (
          <AuditItem key={item.title} {...item} />
        ))}
      </Stack>
      <Stack flexDirection="row" justifyContent="flex-end" className={classes.footer}>
        <GradientButton onClick={onClose} className={classes.footer__btn}>
          Close
        </GradientButton>
      </Stack>
    </InfoBlock>
  </Dialog>
);
