import { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { Box, Stack, Typography } from "@mui/material";

import { getParsedDate } from "@lib/helpers";
import { Campaign, CampaignGoal } from "@lib/types";

import classes from "./Fundraisers.module.scss";

interface Props {
  campaign: Campaign;
}

export const Fundraisers: FC<Props> = ({ campaign }) => (
  <>
    <Stack flexDirection="row" alignItems="center" className={classes.fundraisers}>
      {!campaign.is_subscription && (
        <Stack className={classes.fundraisers__inner}>
          <Typography className={classes.fundraisers__title}>Created By:</Typography>
          <Stack flexDirection="row" alignItems="center" className={classes.fundraisers__person}>
            <Box className={classes.fundraisers__logo}>
              <Icon
                src={campaign.owner?.avatar?.length ? campaign.owner.avatar : "/images/logo.png"}
                style={{ objectFit: "cover" }}
                width={18}
                height={18}
                alt="creator"
              />
            </Box>
            <Typography className={classes.fundraisers__name}>
              {campaign.owner?.display_name}
            </Typography>
          </Stack>
          {!campaign.is_subscription && !!campaign.goal && (
            <Typography className={classes.date}>
              Campaign Start Date <br />{" "}
              {getParsedDate(campaign.goal.starts_at, ` - `) ?? "Unknown"}
            </Typography>
          )}
        </Stack>
      )}

      <Stack className={classes.fundraisers__inner}>
        {!!campaign.cause?.name && (
          <>
            <Typography className={classes.fundraisers__title}>Fundraising For:</Typography>
            <Stack flexDirection="row" alignItems="center" className={classes.fundraisers__person}>
              <Box className={classes.fundraisers__logo}>
                <Icon src="/images/logo.png" width={18} height={18} alt="fundraiser" />
              </Box>
              <Typography className={classes.fundraisers__name}>{campaign.cause.name}</Typography>
            </Stack>
          </>
        )}
        {!campaign.is_subscription && campaign.goal?.type === CampaignGoal.TIMED && (
          <Typography className={classes.date}>
            Campaign End Date <br /> {getParsedDate(campaign.goal.ends_at, ` - `)}
          </Typography>
        )}
      </Stack>
    </Stack>
  </>
);
