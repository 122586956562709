"use client";

import { FC, ReactElement } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { CurrencyInput, InfoBlock } from "@causevest/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import * as yup from "yup";

import { DonationSection } from "@features/donation";

import { useCommonDataContext } from "@contexts";

import { REQUIRED_MESSAGE } from "@lib/constants";
import { Campaign } from "@lib/types";

import classes from "./DonationForm.module.scss";

interface Props {
  title: string;
  defaultValue: number;
  campaign: Campaign;
  subtitle?: string;
  placeholder?: string;
  children?: ReactElement | ReactElement[];
  footer?: ReactElement;
}

export const DonationForm: FC<Props> = ({
  title,
  subtitle,
  children,
  footer,
  defaultValue,
  campaign,
}) => {
  const { currencies } = useCommonDataContext();
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        startAmount: yup
          .number()
          .min(defaultValue, `Amount must be greater than or equal to ${defaultValue}`)
          .required(REQUIRED_MESSAGE),
        currency: yup.string(),
      }),
    ),
    defaultValues: {
      currency: currencies[0].uuid,
      startAmount: defaultValue,
    },
  });

  const initialAmount = methods.watch("startAmount");

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      <InfoBlock>
        <FormProvider {...methods}>
          <Stack className={classes.wrapper}>
            {!!subtitle && (
              <Typography variant="h2" className={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
            <CurrencyInput currencies={currencies} name="startAmount" />
            {children}
            <DonationSection campaign={campaign} initialAmount={initialAmount} />
            {footer}
          </Stack>
        </FormProvider>
      </InfoBlock>
    </>
  );
};
