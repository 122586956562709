import { AppRoutes } from "@lib/types/enums";

export const supportersStub = [
  {
    image: "/images/commentor-1.png",
    name: "Lisa Hampton",
    link: AppRoutes.MAIN,
  },
  {
    image: "/images/commentor-2.png",
    name: "Kehinde Abiodun",
    link: AppRoutes.MAIN,
  },
  {
    image: "/images/commentor-1.png",
    name: "John Doe",
    link: AppRoutes.MAIN,
  },
  {
    image: "/images/commentor-2.png",
    name: "Joe Biden",
    link: AppRoutes.MAIN,
  },
  {
    image: "/images/commentor-1.png",
    name: "Richie Defoe",
    link: AppRoutes.MAIN,
  },
  {
    image: "/images/commentor-2.png",
    name: "Harry Potter",
    link: AppRoutes.MAIN,
  },
];

export const paymentMethods = [
  {
    image: "/images/payment-1.png",
    value: "card",
    name: "Card",
  },
  {
    image: "/images/payment-2.svg",
    value: "wire",
    name: "Wire",
  },
  {
    image: "/images/payment-3.svg",
    value: "paypal",
    name: "PayPal",
  },
];
