"use client";

import { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import {
  FileInput,
  ModalActionsField,
  MuiSelect,
  Preloader,
  TextArea,
  TextInput,
  onInputErrorHandle,
} from "@causevest/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogContent, MenuItem, Stack } from "@mui/material";
import * as yup from "yup";

import { CampaignCreationModal } from "@features/campaign";

import { useCommonDataContext } from "@contexts";

import { REQUIRED_MESSAGE } from "@lib/constants";
import common from "@lib/styles/common.module.scss";
import form from "@lib/styles/form.module.scss";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values: FieldValues) => Promise<void>;
  isLoading: boolean;
}

const schema = yup.object().shape({
  name: yup.string().required(REQUIRED_MESSAGE),
  description: yup.string().required(REQUIRED_MESSAGE),
  category: yup.string().required(REQUIRED_MESSAGE),
  currency: yup.string().required(REQUIRED_MESSAGE),
  primary_image: yup.string().required(REQUIRED_MESSAGE),
  business: yup.string(),
  other: yup.boolean(),
});

export const CreateCauseModal: FC<Props> = ({ isOpen, handleClose, onSubmit, isLoading }) => {
  const { categories, currencies, businesses } = useCommonDataContext();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      category: "",
      name: "",
      description: "",
      primary_image: "",
      business: businesses[0].uuid,
      currency: currencies[0].uuid,
      other: true,
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onHandleSubmit = async (values: FieldValues) => {
    await onSubmit(values);
    reset();
  };

  return (
    <CampaignCreationModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Support a Cause not yet on the Causevest Network."
      hint="The more information you can give us the easier it will be for us to track down and find this cause."
    >
      <FormProvider {...methods}>
        <DialogContent>
          {isLoading ? (
            <Stack sx={{ width: "100%", height: "490px" }}>
              <Box sx={{ margin: "auto" }}>
                <Preloader />
              </Box>
            </Stack>
          ) : (
            <Stack className={form.wrapper}>
              <Stack className={form.row}>
                <TextInput
                  name="name"
                  label="Name"
                  placeholder="What is this cause for?"
                  className={common.input}
                  error={!!errors.name}
                  errorMessage={onInputErrorHandle(errors.name?.message)}
                  required
                />
                <MuiSelect
                  id="category"
                  name="category"
                  label="Category"
                  className={common.input}
                  placeholderValue="Select a Category"
                  error={!!errors.category}
                  errorMessage={onInputErrorHandle(errors.category?.message)}
                  required
                >
                  {categories.map((category) => (
                    <MenuItem key={category.name} value={category.uuid}>
                      {category.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </Stack>
              <Stack className={form.row}>
                <MuiSelect
                  name="currency"
                  id="currency"
                  label="Currency"
                  className={common.input}
                  error={!!errors.currency}
                  errorMessage={onInputErrorHandle(errors.currency?.message)}
                  required
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency.uuid} value={currency.uuid}>
                      {currency.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
                {!!businesses.length && (
                  <MuiSelect
                    name="business"
                    id="business"
                    label="Business"
                    className={common.input}
                  >
                    {businesses.map((business) => (
                      <MenuItem key={business.uuid} value={business.uuid}>
                        {business.display_name}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                )}
              </Stack>
              <Stack className={form.row}>
                <TextArea
                  name="description"
                  label="Description"
                  placeholder="Tell us briefly why you are passionate about this cause…"
                  errorMessage={errors.description?.message}
                  error={!!errors.description}
                  rows={6}
                  fullWidth
                  required
                />
                <FileInput
                  name="primary_image"
                  title="Upload Primary Image"
                  error={errors.primary_image}
                  required
                />
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <ModalActionsField onClose={handleClose} onSubmit={handleSubmit(onHandleSubmit)} />
      </FormProvider>
    </CampaignCreationModal>
  );
};
