import React, { FC } from "react";

import { Preloader } from "@causevest/ui-kit";
import { Box, Stack } from "@mui/material";

import { RaiserCampaignMenu, useCampaignPersist } from "@features/campaign";

import common from "@lib/styles/common.module.scss";
import { Campaign } from "@lib/types";

import { CampaignMainInfo } from "./CampaignMainInfo";
import { SubscriptionMainInfo } from "./SubscriptionMainInfo";

interface Props {
  campaign: Campaign;
}

export const RaiserInner: FC<Props> = ({ campaign }) => {
  const persistedCampaign = useCampaignPersist(campaign);
  const currentCampaign = persistedCampaign as Campaign;

  if (!Object.keys(persistedCampaign).length) {
    return (
      <Stack sx={{ width: "100%", minHeight: "410px" }}>
        <Preloader />
      </Stack>
    );
  }

  return (
    <Box className={common.shadowBlock}>
      {campaign.is_subscription ? (
        <SubscriptionMainInfo campaign={currentCampaign} />
      ) : (
        <CampaignMainInfo campaign={currentCampaign} />
      )}
      <RaiserCampaignMenu campaign={currentCampaign} />
    </Box>
  );
};
