import { FC, ReactElement } from "react";

import { Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import clsx from "clsx";

import classes from "./CampaignCreationModal.module.scss";
import { Steps } from "./Steps";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement | ReactElement[];
  title: string;
  hint: string;
  stepsNum?: number;
  step?: number;
}

export const CampaignCreationModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  children,
  hint,
  stepsNum,
  step,
}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle sx={{ p: 0 }}>
      <Stack className={classes.titleInner}>
        {step !== undefined && (
          <Typography className={classes.subtitle}>
            Just few quick steps required to get started,
            <br />
            kindly provide the required details below.
          </Typography>
        )}
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        {step !== undefined && stepsNum !== undefined && (
          <Steps step={step} className={classes.steps__spaces} steps={stepsNum} />
        )}
        <Typography
          className={clsx(classes.hint, {
            [classes._noSteps]: step === undefined,
          })}
        >
          {hint}
        </Typography>
      </Stack>
    </DialogTitle>
    {children}
  </Dialog>
);
