"use client";

import { useEffect } from "react";

import { useCampaignContext } from "@features/campaign";

import { Campaign } from "@lib/types";

export const useCampaignPersist = (campaign: Campaign): Campaign => {
  const { persistedCampaign, setPersistedCampaignData, cleanup } = useCampaignContext();

  useEffect(() => {
    setPersistedCampaignData(campaign);

    return () => cleanup();
  }, [campaign, cleanup, setPersistedCampaignData]);

  return persistedCampaign as Campaign;
};
