import React, { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";

import {
  CurrencyInput,
  MuiDialog,
  MuiSelect,
  Preloader,
  Switcher,
  TextArea,
  TextInput,
} from "@causevest/ui-kit";
import { LoggedInSection } from "@components";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, MenuItem, Stack } from "@mui/material";
import * as yup from "yup";

import DonationModalAside from "@features/donation/modals/DonationModalAside";

import { useCommonDataContext, useSession } from "@contexts";

import { REQUIRED_MESSAGE } from "@lib/constants";
import common from "@lib/styles/common.module.scss";
import { Campaign, FormReturn } from "@lib/types";

import { useDonationContext } from "../DonationContext";
import classes from "./StepModal.module.scss";

interface Props {
  campaign: Partial<Campaign>;
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values: FieldValues) => Promise<void>;
  isLoading?: boolean;
  initialAmount?: number;
}

const schema = yup.object().shape({
  comment: yup.string(),
  email: yup.string(),
  first_name: yup.string(),
  last_name: yup.string(),
  country: yup.string(),
  postal_code: yup.string(),
  donate_as: yup.string(),
});

export default function StepOneModal({
  campaign,
  isOpen,
  handleClose,
  onSubmit,
  isLoading,
  initialAmount,
}: Props) {
  const { user } = useSession();
  const { countries, currencies } = useCommonDataContext();
  const { setAnonymousUserData, clearAnonymousUserData } = useDonationContext();
  const minAmount = campaign.goal?.minimum_amount ?? 1;

  const methods = useForm({
    resolver: yupResolver(
      schema.shape({
        amount: yup
          .number()
          .min(minAmount, `Amount must be greater than or equal to ${minAmount}`)
          .required(REQUIRED_MESSAGE),
        currency: yup.string(),
      }),
    ),
    defaultValues: {
      amount: minAmount,
      donate_as: "false",
      currency: currencies[0].uuid,
    },
  });

  const {
    watch,
    reset,
    formState: { errors },
  } = methods;

  const donateAs = !user ? "true" : watch("donate_as");

  const onSubmitIntent = async ({ amount, currency, comment, ...values }: FieldValues) => {
    setAnonymousUserData({ amount, ...values });
    await onSubmit({ amount });
  };

  const onCloseModal = () => {
    handleClose();
    clearAnonymousUserData();
  };

  useEffect(() => {
    if (initialAmount) {
      reset({ amount: initialAmount });
    }
  }, [initialAmount]); // eslint-disable-line

  return (
    <MuiDialog
      isOpen={isOpen}
      handleClose={onCloseModal}
      methods={methods as unknown as FormReturn}
      title="Make a Donation"
      onSubmit={onSubmitIntent}
      submitBtnText="Next"
      titleActionBlock={<LoggedInSection onClose={onCloseModal} />}
    >
      <Stack className={classes.wrapper}>
        {isLoading ? (
          <Stack sx={{ width: "100%", height: "100%" }}>
            <Box sx={{ m: "auto" }}>
              <Preloader />
            </Box>
          </Stack>
        ) : (
          <Stack className={classes.form}>
            <CurrencyInput
              placeholder="ex: 100"
              name="amount"
              label="How much would you like to give?"
              error={errors.amount?.message}
              currencies={currencies}
              required
            />
            {!!user && (
              <Switcher name="donate_as" items={["Yourself", "Anonymous"]} label="Donate As" />
            )}
            {donateAs === "true" && (
              <>
                <TextInput
                  label="Email"
                  name="email"
                  className={common.input}
                  placeholder="Type your email here"
                />
                <TextInput
                  label="First Name"
                  name="first_name"
                  className={common.input}
                  placeholder="Type your first name here"
                />
                <TextInput
                  label="Last Name"
                  name="last_name"
                  className={common.input}
                  placeholder="Type your last name here"
                />
                <MuiSelect
                  name="country"
                  id="country"
                  label="Country of Residence"
                  placeholderValue="Select a country"
                  className={common.input}
                  fullWidth
                >
                  {countries.map((country) => (
                    <MenuItem key={country.uuid} value={country.uuid}>
                      {country.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
                <TextInput
                  label="Postal Code"
                  name="postal_code"
                  className={common.input}
                  placeholder="Type your postal code here"
                />
              </>
            )}
            <TextArea
              label="Comment"
              name="comment"
              placeholder="Type your comment here"
              rows={3}
            />
          </Stack>
        )}
        <DonationModalAside campaign={campaign} />
      </Stack>
    </MuiDialog>
  );
}
