"use client";

import { FC, useState } from "react";
import { FieldValues } from "react-hook-form";

import { ModalActionsField, SliderArrow } from "@causevest/ui-kit";
import { SearchInputSmall } from "@components";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { AxiosError } from "axios";

import { proxyAPIGateway } from "@api";

import { EmptyCampaignsList } from "@features/campaigns";

import { handleErrorToast } from "@lib/helpers";
import { ErrorUnprocessable, CausesList as List } from "@lib/types";

import { CausesList } from "./CausesList";
import classes from "./CausesListModal.module.scss";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onCauseSupport: (uuid: string) => void;
  onSubmit: () => void;
  causes?: List;
}

export const CausesListModal: FC<Props> = ({
  isOpen,
  handleClose,
  onSubmit,
  causes,
  onCauseSupport,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [causesList, setCausesList] = useState<List | undefined>(causes);

  const getCausesList = async ({ page }: FieldValues) => {
    try {
      setIsLoading(true);
      const { data } = await proxyAPIGateway.get("/cause/read", {
        params: {
          per_page: 3,
          page,
        },
      });

      setCausesList(data);
    } catch (err) {
      handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
    } finally {
      setIsLoading(false);
    }
  };

  const onHitPrevPage = async () => {
    if (causesList) {
      await getCausesList({ page: causesList.meta.current_page - 1 });
    }
  };

  const onHitNextPage = async () => {
    if (causesList) {
      await getCausesList({
        page: causesList?.meta.current_page + 1,
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} disablePortal>
      <DialogTitle sx={{ p: 0 }}>
        <Stack className={classes.heading}>
          <Typography variant="h2" className={classes.heading__title}>
            Select Cause
          </Typography>
          <SearchInputSmall onSubmit={getCausesList} searchCauses disabled />
          {!!causesList?.meta && (
            <Stack flexDirection="row">
              <SliderArrow
                className="_prev"
                sizes={28}
                onArrowClick={onHitPrevPage}
                disabled={causesList.meta.current_page <= 1}
              >
                <KeyboardArrowLeftIcon />
              </SliderArrow>
              <SliderArrow
                className="_next"
                sizes={28}
                onArrowClick={onHitNextPage}
                disabled={causesList.meta.current_page === causesList.meta.last_page}
              >
                <KeyboardArrowRightIcon />
              </SliderArrow>
            </Stack>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        {causesList?.data.length ? (
          <CausesList list={causesList} isLoading={isLoading} onCauseSubmit={onCauseSupport} />
        ) : (
          <EmptyCampaignsList>No causes found</EmptyCampaignsList>
        )}
      </DialogContent>
      <ModalActionsField onClose={handleClose} onSubmit={onSubmit} submitBtnText="Other Cause" />
    </Dialog>
  );
};
