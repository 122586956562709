import { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

import { Comment } from "@lib/types";

import classes from "./Thread.module.scss";

interface Props {
  comment: Comment;
}

export const SubComment: FC<Props> = ({ comment }) => (
  <Stack className={classes.subcomment}>
    <Stack flexDirection="row" alignItems="center" className={classes.subcomment__person}>
      <Icon
        src={comment.user.avatar.length ? comment.user.avatar : "/images/logo-short.svg"}
        width={37}
        height={37}
        alt="commentor"
        className={classes.person__icon}
      />
      <Box>
        <Typography className={classes.subcomment__name}>{comment.user.display_name}</Typography>
        <Typography className={classes.subcomment__time}>
          {dayjs(comment.created_at).toNow(true)}
        </Typography>
      </Box>
    </Stack>
    <Box className={classes.content} dangerouslySetInnerHTML={{ __html: comment.content }} />
  </Stack>
);
