import React, { FC } from "react";

import { GradientButton, Icon, ModalActionsField, MuiDialog } from "@causevest/ui-kit";
import { Stack } from "@mui/material";
import clsx from "clsx";

import DonationModalAside from "@features/donation/modals/DonationModalAside";
import classes from "@features/donation/modals/StepModal.module.scss";

import { useSession } from "@contexts";

import { Campaign } from "@lib/types";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  campaign: Partial<Campaign>;
}

export const SuccessDonationModal: FC<Props> = ({ isOpen, onClose, campaign }) => {
  const { user } = useSession();

  return (
    <MuiDialog
      isOpen={isOpen}
      handleClose={onClose}
      title="Thanks!"
      bottomContent={
        <ModalActionsField onClose={onClose} onSubmit={onClose} submitBtnText="Finish" />
      }
    >
      <Stack className={classes.wrapper}>
        <div className={clsx(classes.form, classes._light)}>
          <div className={clsx(classes.greetings, classes._light)}>
            <p className={classes.greetings__description}>
              {`Thanks ${user?.display_name}!`} <br />
              Share to help the campaign achieve their goal!
            </p>
            <GradientButton className={classes.shareBtn}>
              <Icon
                width={16}
                height={14}
                src="/images/share.svg"
                alt="share icon"
                className={classes.shareBtn__icon}
              />
              <span>Share</span>
            </GradientButton>
          </div>
          <DonationModalAside campaign={campaign} />
        </div>
      </Stack>
    </MuiDialog>
  );
};
