"use client";

import { FC, ReactElement, createContext, useCallback, useContext, useState } from "react";

import { User } from "@lib/types";

interface Props {
  children: ReactElement | ReactElement[];
  user?: Partial<User>;
}

const noopFunction = () => console.warn("Initialize User Context Provider first");

interface ReturnType {
  setUserData: (data: Partial<User>, persist?: boolean) => void;
  resetUserData: () => void;
  user?: Partial<User>;
}

export const SessionContext = createContext<ReturnType>({
  resetUserData: noopFunction,
  setUserData: noopFunction,
});

export const useSession = (): ReturnType => useContext(SessionContext);

export const SessionProvider: FC<Props> = ({ children, user: persistedUser }) => {
  const [user, setUser] = useState<Partial<User> | undefined>(persistedUser);

  const setUserData = useCallback(async (payload: Partial<User>) => {
    setUser((prev) => ({ ...prev, ...payload }));
  }, []);

  const resetUserData = useCallback(async () => {
    setUser(undefined);
  }, []);

  return (
    <SessionContext.Provider value={{ user, setUserData, resetUserData }}>
      {children}
    </SessionContext.Provider>
  );
};
