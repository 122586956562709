"use client";

import { FC, useMemo } from "react";

import { Box, Typography } from "@mui/material";
import clsx from "clsx";

import { setCurrency } from "@lib/helpers";
import { Goal } from "@lib/types";

import classes from "./FundsBar.module.scss";

interface Props {
  goal: Goal;
  donors?: number;
  accented?: boolean;
}

export const FundsBar: FC<Props> = ({ goal, donors, accented }) => {
  const barWidth = useMemo(() => {
    const onePercent = goal.maximum_amount / 100;
    return `${+goal.amount / onePercent}%`;
  }, [goal.amount, goal.maximum_amount]);

  return (
    <Box className={classes.funds}>
      <Typography className={clsx(classes.funds__count, { [classes._accented]: accented })}>
        <strong>
          {setCurrency(goal.currency.iso4217)}&nbsp;{goal.amount ?? 0}
        </strong>{" "}
        &nbsp;/&nbsp; {goal.maximum_amount}
        &nbsp; raised {donors ? ` by ${donors} donors` : ""}
      </Typography>
      <Box className={classes.funds__bar}>
        <Box className={clsx(classes.funds__bar, classes._active)} style={{ width: barWidth }} />
      </Box>
    </Box>
  );
};
