"use client";

import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { MuiSelect, TextInput, onInputErrorHandle } from "@causevest/ui-kit";
import { MenuItem, Stack, SxProps } from "@mui/material";

import common from "@lib/styles/common.module.scss";
import form from "@lib/styles/form.module.scss";
import { CampaignGoalTypes, Currency } from "@lib/types";

interface Props {
  currencies: Currency[];
  onChangeForm?: (type: CampaignGoalTypes) => void;
  sx?: SxProps;
}

export const GoalTypesForm: FC<Props> = ({ onChangeForm, currencies, sx }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const goalType = useWatch({ control, name: "goal_type" });

  useEffect(() => {
    if (onChangeForm) {
      onChangeForm(goalType);
    }
  }, [goalType, onChangeForm]);

  return (
    <Stack className={form.wrapper} sx={sx}>
      <Stack className={form.row}>
        <TextInput
          name="maximum_amount"
          type="number"
          placeholder="Goal amount"
          label="Goal Amount ($)"
          className={common.input}
          errorMessage={onInputErrorHandle(errors.maximum_amount?.message as string)}
          fullWidth
          required
        />
        <TextInput
          name="recommended_amount"
          type="number"
          placeholder="Enter recommended amount"
          label="Recommended Amount ($)"
          className={common.input}
          errorMessage={onInputErrorHandle(errors.recommended_amount?.message as string)}
          fullWidth
          required
        />
      </Stack>
      <Stack className={form.row}>
        <TextInput
          name="minimum_amount"
          type="number"
          placeholder="Preferred minimum amount"
          label="Min Amount ($)"
          className={common.input}
          errorMessage={onInputErrorHandle(errors.minimum_amount?.message as string)}
          fullWidth
          required
        />
        <MuiSelect name="currency" id="currency" label="Currency" className={common.input}>
          {currencies.map((currency) => (
            <MenuItem key={currency.uuid} value={currency.uuid}>
              {currency.name}
            </MenuItem>
          ))}
        </MuiSelect>
      </Stack>
    </Stack>
  );
};
