"use client";

import { CloseIcon } from "next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon";

import React, { FC, useState } from "react";

import { ShareBar } from "@/src/components/ShareBar";
import { GradientButton, Icon, MuiButton } from "@causevest/ui-kit";
import { Stack } from "@mui/material";

export const ShareSection: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>();

  return (
    <Stack gap="15px" alignItems="center">
      <GradientButton sx={{ width: "170px", height: "35px" }} onClick={() => setIsOpen(true)}>
        <Icon
          width={16}
          height={14}
          src="/images/share.svg"
          alt="share icon"
          sx={{ height: "17px" }}
        />
        <span>Share</span>
      </GradientButton>
      <Stack
        flexDirection="row"
        alignItems="center"
        flexWrap="nowrap"
        sx={{ display: isOpen ? "flex" : "none" }}
      >
        <ShareBar url={process.env.ORIGIN_URL as string} title="Causevest" />
        <MuiButton variant="text" onClick={() => setIsOpen(false)} style={{ background: "none" }}>
          <CloseIcon />
        </MuiButton>
      </Stack>
    </Stack>
  );
};
