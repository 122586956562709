import React, { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { DonationForm } from "@components";
import { Box, Stack, Typography } from "@mui/material";

import { Activity } from "@features/campaign/Activity";

import { getParsedDate } from "@lib/helpers";
import { Campaign } from "@lib/types";

import classes from "./DonorView.module.scss";

interface Props {
  campaign: Campaign;
}

export const CampaignAside: FC<Props> = ({ campaign }) => (
  <Stack className={classes.aside}>
    <Stack className={classes.aside__item}>
      <DonationForm
        title="Support This Cause"
        subtitle="Donate any amount"
        defaultValue={campaign.goal.minimum_amount}
        campaign={campaign}
      />
    </Stack>
    {campaign.rewards?.map((reward) => (
      <Stack key={reward.id} className={classes.aside__item}>
        <DonationForm
          title={reward.title}
          subtitle={`Donate $ ${reward.amount} or more`}
          defaultValue={reward.amount}
          campaign={campaign}
          footer={
            <Stack className={classes.aside__footer}>
              <Typography>
                <span>Limited:</span>
                {` ${reward.limit} Claimed so far`}
              </Typography>
              <Typography>0 Donors</Typography>
            </Stack>
          }
        >
          <>
            {!!reward.image.length && (
              <Icon
                src={reward.image}
                alt="reward img"
                width={200}
                height={200}
                style={{ objectFit: "cover" }}
              />
            )}
            <Stack className={classes.aside__children}>
              <Box>
                <Typography sx={{ fontWeight: 500 }}>Reward Description</Typography>
                <Typography>{reward.description}</Typography>
              </Box>
              <Stack flexDirection="row" flexWrap="wrap" gap="20px">
                <Stack alignItems="center">
                  <Typography sx={{ fontWeight: 500 }}>Delivery Date</Typography>
                  <Typography>{getParsedDate(reward.estimated_delivery, "-")}</Typography>
                </Stack>
                <Stack alignItems="center">
                  <Typography sx={{ fontWeight: 500 }}>Quantity</Typography>
                  <Typography>{reward.quantity}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </>
        </DonationForm>
      </Stack>
    ))}
    <Stack className={classes.aside__item}>
      <Activity />
    </Stack>
  </Stack>
);
