import { FC } from "react";

import { Icon, MuiButton, Preloader } from "@causevest/ui-kit";
import { Box, Stack, Typography } from "@mui/material";

import { CausesList as List } from "@lib/types";

import classes from "./CausesListModal.module.scss";

interface Props {
  list: List;
  onCauseSubmit: (uuid: string) => void;
  isLoading: boolean;
}

export const CausesList: FC<Props> = ({ list, onCauseSubmit, isLoading }) => {
  if (isLoading) {
    return (
      <Stack sx={{ width: "740px", maxWidth: "100%", height: "320px" }}>
        <Preloader />
      </Stack>
    );
  }

  return (
    <Stack className={classes.inner}>
      {list?.data.map((cause) => (
        <Stack key={cause.uuid} className={classes.item}>
          <Stack flexDirection="row" alignItems="center" className={classes.item__heading}>
            <Icon
              src={cause.primary_image?.length ? cause.primary_image : "/images/logo-short.svg"}
              alt="cause img"
              width={70}
              height={70}
              style={{ objectFit: "cover" }}
              priority
            />
            <Stack>
              <Typography className={classes.title}>{cause.name}</Typography>
              <Typography className={classes.description}>{cause.description}</Typography>
            </Stack>
          </Stack>
          <Box>
            <Typography className={classes.location__heading}>Location:</Typography>
            <Typography className={classes.location__place}>USA</Typography>
          </Box>
          <MuiButton
            variant="text"
            disableRipple
            className={classes.support}
            onClick={() => onCauseSubmit(cause.uuid)}
          >
            Support Cause
          </MuiButton>
        </Stack>
      ))}
    </Stack>
  );
};
