"use client";

import { FC, ReactElement } from "react";

import { Icon } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";

import classes from "./AuditTrailPopup.module.scss";

interface Props {
  icon: string;
  date: string;
  title: string;
  avatar: string;
  event: string | ReactElement;
}

export const AuditItem: FC<Props> = ({ icon, date, avatar, title, event }) => (
  <Stack className={classes.wrapper}>
    <Stack flexDirection="row" alignItems="center" className={classes.block}>
      <Icon src={icon} width={11} height={9} alt="event icon" />
      <Typography className={classes.date}>{date}</Typography>
      <Typography className={classes.title}>{title}</Typography>
    </Stack>
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      className={classes.block}
    >
      {typeof event === "string" ? (
        <Typography className={classes.title}>{event}</Typography>
      ) : (
        event
      )}
      <Icon src={avatar} width={15} height={15} alt="avatar" className={classes.avatar} />
    </Stack>
  </Stack>
);
