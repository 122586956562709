import { useCallback } from "react";

import { campaignsAPI } from "@api";

import { useCampaignContext } from "@features/campaign";

export const useCampaignOptimisticUpdate = (): (() => Promise<void>) => {
  const { persistedCampaign, setPersistedCampaignData } = useCampaignContext();

  return useCallback(async () => {
    if (persistedCampaign.uuid) {
      const updatedCampaign = await campaignsAPI.getCampaign(persistedCampaign.uuid);
      setPersistedCampaignData(updatedCampaign);
    }
  }, [persistedCampaign.uuid, setPersistedCampaignData]);
};
