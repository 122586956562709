"use client";

import { FC, ReactElement, createContext, useCallback, useContext, useReducer } from "react";

import { Campaign, NewCampaign } from "@lib/types";

interface Props {
  children: ReactElement | ReactElement[];
}

interface CampaignModalsState {
  isCampaignTypeScreenOpen: boolean;
  isCauseChoiceOpen: boolean;
  isCausesListOpen: boolean;
  isCreateCauseOpen: boolean;
  isCampaignInformationOpen: boolean;
  isSubscriptionInformationOpen: boolean;
  isTiersModalOpen: boolean;
  isFundsModalOpen: boolean;
  isCampaignSettingsOpen: boolean;
}

type PayloadType = Partial<NewCampaign | Campaign | CampaignModalsState> | ReturnType;

interface ModalsState {
  modals: CampaignModalsState;
}

interface CampaignData extends ModalsState {
  newCampaign: Partial<NewCampaign>;
  persistedCampaign: Partial<Campaign>;
}

interface ReturnType extends CampaignData {
  setCampaignData: (payload: PayloadType) => void;
  setPersistedCampaignData: (payload: PayloadType) => void;
  changeModals: (payload: PayloadType) => void;
  cleanup: () => void;
}

interface ChangeAction {
  type: "newCampaign" | "persistedCampaign" | "modals" | "cleanup";
  payload?: PayloadType;
}

const initialCampaignModalsState: CampaignModalsState = {
  isCampaignTypeScreenOpen: false,
  isCauseChoiceOpen: false,
  isCausesListOpen: false,
  isCreateCauseOpen: false,
  isCampaignInformationOpen: false,
  isSubscriptionInformationOpen: false,
  isTiersModalOpen: false,
  isFundsModalOpen: false,
  isCampaignSettingsOpen: false,
};

const stubInitializeFunction = () => console.warn("Initialize Campaign Context Provider first");

const initialState: ReturnType = {
  newCampaign: {},
  persistedCampaign: {},
  modals: initialCampaignModalsState,
  setCampaignData: stubInitializeFunction,
  setPersistedCampaignData: stubInitializeFunction,
  changeModals: stubInitializeFunction,
  cleanup: stubInitializeFunction,
};

function campaignReducer(state: CampaignData, action: ChangeAction): CampaignData {
  switch (action.type) {
    case "newCampaign": {
      return { ...state, newCampaign: { ...state.newCampaign, ...action.payload } as NewCampaign };
    }

    case "persistedCampaign": {
      return {
        ...state,
        persistedCampaign: { ...state.persistedCampaign, ...action.payload } as Campaign,
      };
    }

    case "modals": {
      return { ...state, modals: { ...state.modals, ...action.payload } };
    }

    case "cleanup": {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const CampaignContext = createContext<ReturnType>(initialState);

export const useCampaignContext = (): ReturnType => useContext(CampaignContext);

export const CampaignProvider: FC<Props> = ({ children }) => {
  const [{ newCampaign, persistedCampaign, modals }, dispatch] = useReducer(
    campaignReducer,
    initialState,
  );

  const setCampaignData = useCallback(
    (payload: PayloadType) => dispatch({ type: "newCampaign", payload }),
    [],
  );

  const setPersistedCampaignData = useCallback(
    (payload: PayloadType) => dispatch({ type: "persistedCampaign", payload }),
    [],
  );

  const changeModals = useCallback(
    (payload: PayloadType) => dispatch({ type: "modals", payload }),
    [],
  );

  const cleanup = useCallback(() => dispatch({ type: "cleanup" }), []);

  return (
    <CampaignContext.Provider
      value={{
        newCampaign,
        persistedCampaign,
        modals: modals ?? initialState.modals,
        setPersistedCampaignData,
        setCampaignData,
        changeModals,
        cleanup,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
