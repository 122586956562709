import { FC, ReactElement, createContext, useCallback, useContext, useState } from "react";

interface AnonymousUserInfo {
  amount: number;
  currency: string;
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  postalCode: string;
}

export interface ReturnType extends Partial<AnonymousUserInfo> {
  setAnonymousUserData: (info: Partial<AnonymousUserInfo>) => void;
  clearAnonymousUserData: () => void;
}

interface Props {
  children: ReactElement;
}

const initialValues: Partial<AnonymousUserInfo> = {};
const noopFunction = () => console.warn("Initialize Donation Context Provider first");

const DonationContext = createContext<ReturnType>({
  ...initialValues,
  setAnonymousUserData: noopFunction,
  clearAnonymousUserData: noopFunction,
});

export const useDonationContext = (): ReturnType => useContext(DonationContext);

export const DonationContextProvider: FC<Props> = ({ children }) => {
  const [anonymousUserInfo, setUserInfo] = useState<Partial<AnonymousUserInfo>>(initialValues);

  const setAnonymousUserData = useCallback((info: Partial<AnonymousUserInfo>) => {
    setUserInfo((prev) => ({ ...prev, ...info }));
  }, []);

  const clearAnonymousUserData = useCallback(() => {
    setUserInfo(initialValues);
  }, []);

  return (
    <DonationContext.Provider
      value={{ ...anonymousUserInfo, setAnonymousUserData, clearAnonymousUserData }}
    >
      {children}
    </DonationContext.Provider>
  );
};
