import React, { FC } from "react";

import { Preloader } from "@causevest/ui-kit";
import { EntityCard } from "@components";
import { Grid, Stack } from "@mui/material";

import { EmptyCampaignsList } from "@features/campaigns";

import classes from "@lib/styles/campaigns.module.scss";
import { Campaign } from "@lib/types";

interface Props {
  isLoading: boolean;
  campaigns?: Campaign[];
}

export const CampaignsList: FC<Props> = ({ isLoading, campaigns }) => {
  if (isLoading) {
    return (
      <Stack justifyContent="center" sx={{ minHeight: "calc(100vh - 410px)" }}>
        <Preloader />
      </Stack>
    );
  }

  if (!campaigns?.length) {
    return (
      <EmptyCampaignsList>
        Looks like, no campaigns found. Try to modify your filters
      </EmptyCampaignsList>
    );
  }

  return (
    <Grid container spacing={4} columns={{ xs: 4, sm: 8, md: 12 }}>
      {campaigns.map((campaign, key) => (
        <Grid key={`list_campaign_${key}`} item xs={12} sm={4} md={4}>
          <EntityCard className={classes.campaign} campaign={campaign} />
        </Grid>
      ))}
    </Grid>
  );
};
