import { FC } from "react";
import { FieldValues, FormProvider } from "react-hook-form";

import {
  FileInput,
  ModalActionsField,
  MuiSelect,
  TextArea,
  TextInput,
  parseDirtyValues,
} from "@causevest/ui-kit";
import { DialogContent, MenuItem, Stack } from "@mui/material";
import clsx from "clsx";

import { useTierCreationForm } from "@features/campaign";

import { useCommonDataContext } from "@contexts";

import common from "@lib/styles/common.module.scss";
import { Tier } from "@lib/types";

import classes from "./CampaignModal.module.scss";

interface Props {
  onSubmit: (values: FieldValues) => Promise<void>;
  onClose: () => void;
  onPrev?: () => void;
  submitBtnText?: string;
  tier?: Partial<Tier>;
}

export const CreateTierForm: FC<Props> = ({ onSubmit, onPrev, tier, onClose, submitBtnText }) => {
  const { currencies } = useCommonDataContext();
  const { methods } = useTierCreationForm(currencies[0].uuid, tier);

  const {
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = methods;

  const isDisabledBtn = !Object.keys(dirtyFields).length;

  const onFormSubmit = async (values: FieldValues) => {
    if (tier) {
      const dirtyValues = parseDirtyValues(dirtyFields, values);

      if (dirtyValues) {
        await onSubmit(dirtyValues);
        reset();
      }
    } else {
      await onSubmit({ ...values, type: "open-ended", is_subscription: "true" });
      reset();
    }
  };

  return (
    <FormProvider {...methods}>
      <DialogContent>
        <Stack className={classes.wrapper}>
          <TextInput
            name="name"
            placeholder="Give a short name to your tier"
            label="Name"
            className={common.input}
            errorMessage={errors.name?.message as string}
            error={!!errors.name}
            fullWidth
            required
          />
          <Stack className={classes.row}>
            <TextInput
              name="amount"
              type="number"
              placeholder="Enter donation amount"
              label="Amount"
              className={common.input}
              errorMessage={errors.amount?.message as string}
              error={!!errors.amount}
              fullWidth
              required
            />
            <MuiSelect
              name="currency"
              id="currency"
              label="Currency"
              className={clsx(common.input, classes.currency)}
            >
              {currencies.map((currency) => (
                <MenuItem key={currency.uuid} value={currency.uuid}>
                  {currency.name}
                </MenuItem>
              ))}
            </MuiSelect>
          </Stack>
          <Stack className={classes.row}>
            <TextArea
              name="reward_description"
              label="Reward Description"
              placeholder="Tell us more about the reward"
              errorMessage={errors.reward_description?.message}
              error={!!errors.reward_description}
              rows={6}
              fullWidth
              required
            />
            <FileInput
              name="tier_image"
              title="Upload Image"
              initialImage={tier?.tier_image}
              error={errors.tier_image}
              required
            />
          </Stack>
        </Stack>
      </DialogContent>
      <ModalActionsField
        onClose={onClose}
        onSubmit={handleSubmit(onFormSubmit)}
        onPrev={onPrev}
        submitBtnText={submitBtnText}
        disabled={isDisabledBtn}
      />
    </FormProvider>
  );
};
