"use client";

import { FC } from "react";
import { FieldError, FieldValues, FormProvider } from "react-hook-form";

import {
  FileInput,
  ModalActionsField,
  MuiSelect,
  TextArea,
  TextInput,
  onInputErrorHandle,
} from "@causevest/ui-kit";
import { DialogContent, MenuItem, Stack } from "@mui/material";
import * as yup from "yup";

import { CampaignCreationModal } from "@features/campaign/CampaignCreationModal";
import { useCampaignContext } from "@features/campaign/contexts/CampaignContext";
import { useCampaignInfo } from "@features/campaign/hooks/useCampaignInfo";

import { useCommonDataContext } from "@contexts";

import { REQUIRED_MESSAGE } from "@lib/constants";
import common from "@lib/styles/common.module.scss";
import form from "@lib/styles/form.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onPrev: () => void;
}

export const SubscriptionInformationModal: FC<Props> = ({ isOpen, onClose, onPrev, onSubmit }) => {
  const { newCampaign, setCampaignData, cleanup } = useCampaignContext();
  const { categories } = useCommonDataContext();
  const { methods, minTextCount } = useCampaignInfo(
    {
      description: yup.string().required(REQUIRED_MESSAGE),
      header_image: yup.string().required(REQUIRED_MESSAGE),
    },
    {
      category: newCampaign.category ?? "",
      title: newCampaign.title ?? "",
      short_description: newCampaign.short_description ?? "",
      image: newCampaign.image ?? "",
      description: newCampaign.description ?? "",
      header_image: newCampaign.header_image ?? "",
    },
  );

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onModalClose = () => {
    reset();
    onClose();
  };

  const onSubmitInfo = (values: FieldValues) => {
    setCampaignData(values);
    onSubmit();
  };

  const onFollowPrev = () => {
    reset();
    cleanup();
    onPrev();
  };

  return (
    <CampaignCreationModal
      isOpen={isOpen}
      onClose={onModalClose}
      title="Campaign Information"
      hint="Updates, Rewards and faq will be available after creating your campaign"
      stepsNum={2}
      step={0}
    >
      <FormProvider {...methods}>
        <DialogContent>
          <Stack className={form.wrapper} sx={{ mt: "20px" }}>
            <Stack className={form.row}>
              <TextInput
                name="title"
                label="Title"
                placeholder="What is this campaign for?"
                className={common.input}
                errorMessage={onInputErrorHandle(errors.title?.message)}
                required
              />
              <MuiSelect
                id="category"
                name="category"
                label="Category"
                className={common.input}
                placeholderValue="Select a Category"
                error={!!errors.category}
                errorMessage={onInputErrorHandle(errors.category?.message)}
                fullWidth
                required
              >
                {categories.map((category) => (
                  <MenuItem key={category.name} value={category.uuid}>
                    {category.name}
                  </MenuItem>
                ))}
              </MuiSelect>
            </Stack>
            <Stack className={form.row}>
              <TextArea
                name="short_description"
                label="Short Description of Yourself"
                placeholder="Tell us briefly about yourself"
                errorMessage={errors.short_description?.message}
                error={!!errors.short_description}
                charactersNum={minTextCount}
                rows={7}
                fullWidth
                required
              />
              <FileInput
                title="Upload Primary Image"
                initialImage={newCampaign.image}
                error={errors.image as FieldError}
                required
              />
            </Stack>
            <Stack className={form.row}>
              <TextArea
                name="description"
                label="About Campaign"
                placeholder="Tell us more about this campaign…"
                errorMessage={errors.description?.message}
                error={!!errors.description}
                rows={7}
                fullWidth
                required
              />
              <FileInput
                name="header_image"
                title="Upload Header Image"
                initialImage={newCampaign.header_image}
                error={errors.header_image as FieldError}
                required
              />
            </Stack>
          </Stack>
        </DialogContent>
        <ModalActionsField
          onClose={onModalClose}
          onPrev={onFollowPrev}
          onSubmit={handleSubmit(onSubmitInfo)}
        />
      </FormProvider>
    </CampaignCreationModal>
  );
};
