import { FC } from "react";

import { Icon, MuiButton } from "@causevest/ui-kit";
import { CircularProgress } from "@mui/material";

import classes from "./Thread.module.scss";

interface Props {
  likeCount: number;
  toggleLike: () => Promise<void>;
  isLoading: boolean;
}

export const ThreadLikeBtn: FC<Props> = ({ likeCount, toggleLike, isLoading }) => (
  <MuiButton
    variant="text"
    className={classes.bottom__btn}
    sx={{ justifyContent: "flex-start" }}
    onClick={toggleLike}
    disableRipple
  >
    <Icon
      src={likeCount > 0 ? "/images/heart-filled.svg" : "/images/heart.svg"}
      width={13}
      height={10}
      alt="heart"
      className={classes.bottom__icon}
    />
    {isLoading ? (
      <CircularProgress size={10} />
    ) : (
      <span>{`${likeCount} Like${likeCount % 10 === 1 ? "" : "s"}`}</span>
    )}
  </MuiButton>
);
