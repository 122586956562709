import { FC, useMemo, useState } from "react";

import { CancelButton, GradientButton, Icon, Preloader, useEffectAsync } from "@causevest/ui-kit";
import { UserInList } from "@components";
import { Box, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AxiosError } from "axios";
import { useSwiper } from "swiper/react";

import { proxyAPIGateway } from "@api";

import { TierPaymentElement } from "@features/campaign/TiersListModal/TierPaymentElement";
import classes from "@features/campaign/TiersListModal/TiersListModal.module.scss";

import { useCommonDataContext } from "@contexts";

import { getParsedUserName, handleErrorToast } from "@lib/helpers";
import { ErrorUnprocessable, Tier, User } from "@lib/types";

interface Props {
  tier: Tier;
  owner: Partial<User>;
  onClose: () => void;
  isOpen: boolean;
  setSuccessOpen?: (open: boolean) => void;
  idx?: number;
}

export const TierInModal: FC<Props> = ({
  tier,
  owner,
  onClose,
  isOpen,
  idx = 0,
  setSuccessOpen,
}) => {
  const { activeIndex } = useSwiper();
  const { stripeKey } = useCommonDataContext();
  const [clientSecret, setClientSecret] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isStripePayment, setStripePayment] = useState(false);

  const stripePromise = useMemo(() => loadStripe(stripeKey), [stripeKey]);

  const onPaymentIntent = async () => {
    try {
      const {
        data: { data },
      } = await proxyAPIGateway.post("/payments/intent", {
        amount: tier.amount,
        user_id: owner.uuid,
      });

      if (data.client_secret) {
        if (!clientSecret) {
          setClientSecret(data.client_secret);
        }
      }
    } catch (err) {
      handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
    } finally {
      setIsLoading(false);
    }
  };

  const openSuccessModal = () => {
    onClose();

    if (setSuccessOpen) {
      setSuccessOpen(true);
    }
  };

  useEffectAsync(
    async (awaiter) => {
      if (isOpen && idx === activeIndex) {
        await awaiter(onPaymentIntent());
      }
    },
    [isOpen, activeIndex],
  );

  return (
    <>
      <DialogContent>
        <Stack className={classes.wrapper}>
          <Stack className={classes.inner}>
            <Stack className={classes.tier__wrapper}>
              <Stack className={classes.tier__heading}>
                <Icon
                  src={tier.tier_image?.length ? tier.tier_image : "/images/logo-short.svg"}
                  alt="tier reward"
                  style={{ objectFit: "cover" }}
                  width={140}
                  height={142}
                  priority
                />
                <Stack className={classes.info}>
                  <Stack gap="15px">
                    <Typography className={classes.info__title}>Support</Typography>
                    <Typography
                      className={classes.info__donation}
                    >{`$${tier.amount} per month`}</Typography>
                  </Stack>
                  {!!tier.creators?.length && (
                    <Stack gap="15px">
                      <Typography className={classes.info__title}>Creator(s)</Typography>
                      <Stack gap="15px">
                        {tier.creators?.map((creator) => (
                          <UserInList
                            key={creator.uuid}
                            name={getParsedUserName(creator)}
                            image={creator.image as string}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  )}
                  {!!tier.recipients?.length && (
                    <Stack gap="15px">
                      <Typography className={classes.info__title}>Supporting</Typography>
                      <Stack gap="15px">
                        {tier.recipients?.map((recipient) => (
                          <UserInList
                            key={recipient.uuid}
                            name={getParsedUserName(recipient)}
                            image={recipient.image as string}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              {!!tier.reward_description && (
                <Typography className={classes.tier__description}>
                  {tier.reward_description}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack className={classes.payment}>
            <Typography className={classes.payment__title}>
              Select Recurring Payment Method
            </Typography>
            <Box className={classes.payment__body}>
              {clientSecret ? (
                <Elements options={{ clientSecret }} stripe={stripePromise}>
                  <TierPaymentElement
                    owner={owner}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isStripePayment={isStripePayment}
                    setSuccessOpen={openSuccessModal}
                  />
                </Elements>
              ) : (
                <Stack sx={{ width: "100%", height: "100%" }}>
                  <Box sx={{ m: "auto" }}>
                    <Preloader />
                  </Box>
                </Stack>
              )}
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 0, bottom: 0 }}>
        <Stack flexDirection="row" className={classes.footer}>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <GradientButton onClick={() => setStripePayment(true)} className={classes.submitBtn}>
            Support
          </GradientButton>
        </Stack>
      </DialogActions>
    </>
  );
};
