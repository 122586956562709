import { FC } from "react";

import { Icon, stringAvatar } from "@causevest/ui-kit";
import { Avatar, Box } from "@mui/material";
import clsx from "clsx";

import { getParsedUserName } from "@lib/helpers";
import { Campaign } from "@lib/types";

import classes from "./CreatorAvatar.module.scss";

interface Props {
  campaign: Campaign;
  isBig?: boolean;
}

export const CreatorAvatar: FC<Props> = ({ campaign, isBig }) => (
  <Box className={classes.wrapper}>
    <Avatar
      {...stringAvatar(getParsedUserName(campaign.owner))}
      alt="owner avatar"
      src={campaign.owner?.avatar ?? "/images/logo-short.svg"}
      style={{
        fontSize: "30px",
        width: isBig ? 111 : 80,
        height: isBig ? 111 : 80,
      }}
    />
    <Icon
      src="/images/badge.svg"
      alt="verified"
      width={isBig ? 22 : 18}
      height={isBig ? 22 : 18}
      className={clsx(classes.badge, { [classes._big]: isBig })}
    />
  </Box>
);
