import { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { LockedPost } from "@components";
import { Box, Stack, Typography } from "@mui/material";

import { getParsedDate } from "@lib/helpers";
import { Thread as CurrentThread } from "@lib/types";

import classes from "./Thread.module.scss";
import { ThreadActions } from "./ThreadActions";

interface Props {
  thread: CurrentThread;
}

export const Thread: FC<Props> = ({ thread }) => (
  <Stack className={classes.wrapper}>
    <Stack flexDirection="row" alignItems="center" className={classes.person}>
      <Icon
        src={thread.creator.avatar.length ? thread.creator.avatar : "/images/logo-short.svg"}
        sx={{ overflow: thread.creator.avatar.length ? "hidden" : "visible" }}
        width={37}
        height={37}
        alt="author of thread"
        className={classes.person__icon}
      />
      <Stack gap="5px">
        <Typography variant="h4" className={classes.person__name}>
          {thread.creator.display_name}
        </Typography>
        <Typography variant="h3" className={classes.person__time}>
          {getParsedDate(thread.created_at)}
        </Typography>
      </Stack>
    </Stack>
    <Typography variant="h3" className={classes.title}>
      {thread.title}
    </Typography>
    {thread.locked_at ? (
      <LockedPost />
    ) : (
      <Box className={classes.content} dangerouslySetInnerHTML={{ __html: thread.content }} />
    )}
    <ThreadActions thread={thread} />
  </Stack>
);
