import { FC, lazy } from "react";

interface Props {
  isSubscription: boolean;
  tab: number;
}

const EditCampaign = lazy(() => import("./EditCampaign"));
const Settings = lazy(() => import("./Settings"));
const Statistic = lazy(() => import("./Statistic"));
const ManageRewards = lazy(() => import("./ManageRewards"));
const ManageTiers = lazy(() => import("./ManageTiers"));
const FAQ = lazy(() => import("./FAQ"));
const Threads = lazy(() => import("./Threads"));

export const RaiserCampaignMenuInner: FC<Props> = ({ isSubscription, tab }) => {
  if (isSubscription) {
    return [
      <Statistic key="tab_0" />,
      <EditCampaign key="tab_1" />,
      <ManageTiers key="tab_2" />,
      <FAQ key="tab_3" />,
      <Threads key="tab_4" />,
    ].filter((_, key) => key === tab);
  }

  return [
    <Statistic key="tab_0" />,
    <EditCampaign key="tab_1" />,
    <Settings key="tab_2" />,
    <ManageRewards key="tab_3" />,
    <FAQ key="tab_4" />,
    <Threads key="tab_5" />,
  ].filter((_, key) => key === tab);
};
