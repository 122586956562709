import { UseFormReturn, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { REQUIRED_MESSAGE } from "@lib/constants";

const schema = yup.object().shape({
  maximum_amount: yup.number().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
  recommended_amount: yup.number().required(REQUIRED_MESSAGE),
  minimum_amount: yup.number().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
  currency: yup.string(),
});

export const useGoalTypes = (
  extraValues?: Record<string, string | number | boolean | undefined>,
  extraSchema?: yup.ObjectShape,
): UseFormReturn<Record<string, unknown>> => {
  const currentSchema = extraSchema ? schema?.shape(extraSchema) : schema;

  return useForm({
    resolver: yupResolver(currentSchema),
    progressive: true,
    defaultValues: {
      currency: "",
      ...extraValues,
    },
  });
};
