import Image from "next/image";
import Link from "next/link";

import { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { FundsBar } from "@components";
import { Box, Container, Stack, Typography } from "@mui/material";
import clsx from "clsx";

import { DonateBtnsGroup, Fundraisers } from "@features/campaign";

import { setDaysGap } from "@lib/helpers";
import common from "@lib/styles/common.module.scss";
import { AppRoutes, Campaign } from "@lib/types";

import classes from "./DonorView.module.scss";

interface Props {
  campaign: Campaign;
}

export const CampaignHeader: FC<Props> = ({ campaign }) => (
  <Box className={classes.wrapper}>
    <Container className={classes.inner}>
      <Box className={classes.left}>
        <Image
          src={campaign.image?.length ? campaign.image : "/images/logo-short.svg"}
          alt="campaign pic"
          style={{ objectFit: "cover" }}
          sizes="100%"
          priority
          fill
        />
        <Box className={common.badge}>
          <span>{campaign.category.name}</span>
        </Box>
      </Box>
      <Stack className={clsx(classes.right, { [classes._gap]: !campaign.goal?.ends_at })}>
        <Stack className={classes.right__top}>
          <Typography className={classes.id}>{`CampaignID: ${campaign.uuid}`}</Typography>
          <Link href={AppRoutes.MAIN} className={classes.report}>
            <Stack direction="row" alignItems="center" flexWrap="nowrap" gap="5px">
              <Icon src="/images/report.svg" width={12} height={10} alt="report icon" />
              <Typography className={classes.report__text}>Report this campaign</Typography>
            </Stack>
          </Link>
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-start" className={classes.mobileBadge}>
          <Box className={clsx(common.badge, common._mobileBadge)}>
            <span>{campaign.category.name}</span>
          </Box>
        </Stack>
        <Typography variant="h1" className={classes.title}>
          {campaign.title}
        </Typography>
        <Typography className={classes.description}>{campaign.short_description}</Typography>
        {!!campaign.goal && !campaign.hide_balance && (
          <Box sx={{ mt: "20px" }}>
            <FundsBar goal={campaign.goal} donors={campaign.donor_count} accented />
          </Box>
        )}
        {!!campaign.goal?.ends_at && (
          <Typography
            className={classes.daysLeft}
          >{`${setDaysGap(campaign.goal.ends_at)} days left`}</Typography>
        )}
        <Fundraisers campaign={campaign} />
        <DonateBtnsGroup campaign={campaign} />
      </Stack>
    </Container>
  </Box>
);
