import React, { Dispatch, FC, SetStateAction } from "react";

import { ModalActionsField, MuiDialog, Preloader } from "@causevest/ui-kit";
import { LoggedInSection } from "@components";
import { Box, Stack } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { AxiosError } from "axios";
import clsx from "clsx";

import { useCampaignContext } from "@features/campaign";
import { useDonationContext } from "@features/donation/DonationContext";
import DonationModalAside from "@features/donation/modals/DonationModalAside";
import classes from "@features/donation/modals/StepModal.module.scss";

import { useSession } from "@contexts";

import { handleErrorToast } from "@lib/helpers";
import { Campaign, ErrorUnprocessable } from "@lib/types";

interface Props {
  campaign: Partial<Campaign>;
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  initialAmount?: number;
}

export const StepTwoModal: FC<Props> = ({
  campaign,
  isOpen,
  handleClose,
  onSubmit,
  isLoading,
  setIsLoading,
  initialAmount,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setPersistedCampaignData } = useCampaignContext();
  const { user } = useSession();
  const { clearAnonymousUserData, ...anonymousUserData } = useDonationContext();

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  const onPaymentSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    try {
      setIsLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              email: user?.email ?? anonymousUserData.email,
              name:
                user?.display_name ??
                `${anonymousUserData.firstName} ${anonymousUserData.lastName}`.trim(),
              address: {
                country: user?.address?.country?.name ?? anonymousUserData.country,
                postal_code: user?.address?.postal_code ?? anonymousUserData.postalCode,
              },
            },
          },
        },
        redirect: "if_required",
      });

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          handleErrorToast(error.message || "An error occurred.");
        } else {
          handleErrorToast("An unexpected error occurred.");
        }
      } else {
        setPersistedCampaignData({
          goal: { ...campaign.goal, amount: initialAmount },
        } as unknown as Partial<Campaign>);
        onSubmit();
      }
    } catch (err) {
      handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseModal = () => {
    handleClose();
    clearAnonymousUserData();
  };

  return (
    <MuiDialog
      isOpen={isOpen}
      handleClose={onCloseModal}
      title="Payment"
      titleActionBlock={<LoggedInSection onClose={onCloseModal} />}
      bottomContent={
        <ModalActionsField
          onClose={onCloseModal}
          onSubmit={onPaymentSubmit}
          submitBtnText="Donate"
        />
      }
    >
      <Stack className={classes.wrapper}>
        <Box className={classes.form}>
          {isLoading && (
            <Stack sx={{ width: "100%", height: "100%" }}>
              <Box sx={{ m: "auto" }}>
                <Preloader />
              </Box>
            </Stack>
          )}
          <Box className={clsx(classes.form__payment, { [classes._isLoading]: isLoading })}>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
          </Box>
        </Box>
        <DonationModalAside campaign={campaign} />
      </Stack>
    </MuiDialog>
  );
};
