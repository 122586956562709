import React, {
  ForwardRefExoticComponent,
  MemoExoticComponent,
  ReactElement,
  RefAttributes,
  forwardRef,
  memo,
} from "react";

import { ButtonProps } from "@mui/material/Button";
import { SxProps } from "@mui/system/styleFunctionSx";
import clsx from "clsx";

import { MuiButton } from "../Button";
import { Icon } from "../Icon";
import classes from "./GradientButton.module.scss";

interface Props extends ButtonProps {
  children: string | ReactElement | ReactElement[];
  isArrow?: boolean;
  onClick?: () => void;
  sx?: SxProps;
}

type WithForwardedRefType = MemoExoticComponent<
  ForwardRefExoticComponent<Omit<Props, "ref"> & RefAttributes<HTMLButtonElement>>
>;

export const GradientButton: WithForwardedRefType = memo(
  forwardRef(({ disabled, isArrow, onClick, children, className, sx, ...props }, ref) => (
    <MuiButton
      {...props}
      ref={ref}
      className={clsx(classes.btn, className, {
        [classes._arrowed]: isArrow,
        [classes._disabled]: disabled,
      })}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
    >
      {children}
      {isArrow && <Icon src="/images/chevron-right.svg" alt="chevron" width={10} height={12} />}
    </MuiButton>
  )),
);

GradientButton.displayName = "GradientButton";
