"use client";

import { useRouter } from "next/navigation";

import React, { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { GradientButton, Preloader, TextInput } from "@causevest/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography, capitalize } from "@mui/material";
import * as yup from "yup";

import { useAuth } from "@features/auth";

import { REQUIRED_MESSAGE } from "@lib/constants";
import common from "@lib/styles/common.module.scss";
import { AppRoutes } from "@lib/types";

import classes from "./EmailVerifySuccessInner.module.scss";

const schema = yup.object().shape({
  email: yup.string().email().required(REQUIRED_MESSAGE),
  password: yup.string().required(REQUIRED_MESSAGE),
});

export const EmailVerifySuccessInner: FC = () => {
  const router = useRouter();
  const { isLoading, login } = useAuth();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onHandleSubmit = async (values: FieldValues) => {
    await login(values);
    router.push(AppRoutes.DASHBOARD);
  };

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <Stack className={classes.wrapper}>
      <FormProvider {...methods}>
        <Stack className={classes.inner}>
          <Typography variant="h1" className={classes.title}>
            Log in with social or email
          </Typography>
          <Stack className={classes.inputs}>
            <TextInput
              name="email"
              type="email"
              label="E-Mail Address"
              className={common.input}
              error={!!errors.email}
              errorMessage={errors.email?.message ? capitalize(errors.email.message) : undefined}
              fullWidth
              required
            />
            <TextInput
              name="password"
              type="password"
              label="Password"
              className={common.input}
              error={!!errors.password}
              errorMessage={
                errors.password?.message ? capitalize(errors.password.message) : undefined
              }
              fullWidth
              required
            />
          </Stack>
          <GradientButton
            onClick={handleSubmit(onHandleSubmit)}
            className={classes.loginBtn}
            fullWidth
          >
            Login
          </GradientButton>
          <Box className={classes.info}>
            Causevest is a way to bring the causes you love to life. Causevest does not guarantee
            campaigns or investigate a creator&apos;s ability to complete their project. It is the
            responsibility of the project creator to complete their project as promised, and the
            claims of this project are theirs alone.
          </Box>
        </Stack>
      </FormProvider>
    </Stack>
  );
};
