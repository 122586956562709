import React, { FC } from "react";

import { Box, Stack, Typography } from "@mui/material";

import { FAQ as FAQType } from "@lib/types";

import classes from "./FAQ.module.scss";

interface Props {
  faqs: FAQType[];
}

export const FAQ: FC<Props> = ({ faqs }) => (
  <Stack>
    <Stack className={classes.questions}>
      {faqs.map((faq) => (
        <Box key={faq.question} className={classes.questions__item}>
          <Typography variant="h2" className={classes.title}>
            {`Q: ${faq.question}`}
          </Typography>
          <Typography className={classes.description}>{faq.answer}</Typography>
        </Box>
      ))}
    </Stack>
  </Stack>
);
