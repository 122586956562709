import React, { FC } from "react";

import { Box, Pagination, PaginationProps, Stack } from "@mui/material";
import clsx from "clsx";

import classes from "@lib/styles/campaigns.module.scss";

interface Props extends PaginationProps {
  isMobile?: boolean;
  isFlat?: boolean;
}

export const CampaignsPagination: FC<Props> = ({ isMobile, isFlat, className, ...props }) => (
  <Stack className={clsx(classes.pagination, { [classes._flat]: isFlat }, className)}>
    <Box className={classes.pagination__inner}>
      <Pagination
        {...props}
        showFirstButton={!isMobile}
        showLastButton={!isMobile}
        siblingCount={isMobile ? 0 : undefined}
      />
    </Box>
  </Stack>
);
