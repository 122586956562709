import { FC } from "react";

import { Box, Stack } from "@mui/material";
import clsx from "clsx";

import classes from "./CampaignCreationModal.module.scss";

interface Props {
  step: number;
  steps: number;
  className?: string;
}

export const Steps: FC<Props> = ({ step, steps, className }) => {
  const stepsArray = Array.from(Array(steps));

  return (
    <Stack className={clsx(classes.steps, className)}>
      {stepsArray.map((_, key) => (
        <Box
          key={`step_${key}`}
          className={clsx(classes.step, { [classes._active]: step === key })}
        />
      ))}
    </Stack>
  );
};
