import Image from "next/image";

import { FC } from "react";

import { Box, Container, Stack, Typography } from "@mui/material";

import { CreatorAvatar } from "@features/campaign";

import { getParsedUserName } from "@lib/helpers";
import { Campaign } from "@lib/types";

import classes from "./DonorView.module.scss";

interface Props {
  campaign: Campaign;
}

export const SubscriptionHeader: FC<Props> = ({ campaign }) => (
  <Stack flexDirection="row" className={classes.cheader}>
    <Container>
      <Stack flexDirection="row" className={classes.cheader__inner}>
        <CreatorAvatar campaign={campaign} />
        <Typography className={classes.cheader__title}>
          {getParsedUserName(campaign.owner)}
        </Typography>
      </Stack>
    </Container>
    {campaign.header_image?.length ? (
      <Image
        src={campaign.header_image}
        alt="subscription heading pic"
        style={{ objectFit: "cover" }}
        priority
        fill
      />
    ) : (
      <Box className={classes.cheader__cover} />
    )}
  </Stack>
);
