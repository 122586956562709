"use client";

import { FC } from "react";
import { Doughnut } from "react-chartjs-2";

import { FeedBlock } from "@components";
import { Typography } from "@mui/material";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  currentAmount: number;
  className?: string;
}

export const ConversionsChart: FC<Props> = ({ currentAmount, className }) => {
  return (
    <FeedBlock
      title="Conversion"
      className={className}
      sx={{ marginLeft: "-20px", position: "relative" }}
      isLight
    >
      <Doughnut
        data={{
          labels: ["Last 7 days", "Average"],
          datasets: [
            {
              data: [currentAmount, 100 - currentAmount],
              backgroundColor: ["#00E8FE", "#4880FF"],
              borderWidth: 1,
            },
          ],
        }}
        options={{
          responsive: true,
          cutout: 85,
          plugins: {
            legend: {
              labels: {
                padding: 10,
              },
              position: "bottom" as const,
            },
            title: {
              display: false,
            },
          },
        }}
      />
      <Typography
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          fontSize: "55px",
          color: "#818181",
          marginTop: "-57px",
          marginLeft: "-40px",
        }}
      >
        {`${currentAmount}%`}
      </Typography>
    </FeedBlock>
  );
};
