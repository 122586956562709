import { FC } from "react";

import { Box, Container, Stack } from "@mui/material";
import clsx from "clsx";

import { CreateCampaignSection, RaiserCampaignWrapper } from "@features/campaign";

import { Campaign, CausesList } from "@lib/types";

import { AsideStatisticPanel } from "./AsideStatisticPanel";
import classes from "./RaiserView.module.scss";

interface Props {
  campaign: Campaign;
  causes: CausesList;
}

export const RaiserView: FC<Props> = ({ campaign, causes }) => (
  <Container className={classes.wrapper}>
    <AsideStatisticPanel causes={causes} campaign={campaign} />
    <Stack className={classes.main}>
      <Box className={clsx(classes.aside__createBtn, classes._mobile)}>
        <CreateCampaignSection causes={causes} />
      </Box>
      <RaiserCampaignWrapper campaign={campaign} />
    </Stack>
  </Container>
);
