import { parseQueryFilters } from "@lib/helpers";
import {
  AdvancedAxiosConfig,
  CategoriesList,
  Country,
  Currency,
  SettingsEntity,
  TrustedList,
} from "@lib/types";

import { apiGateway } from "./gateway";

export const commonAPI = {
  getSettings: async <T>(filters?: Partial<T>, options?: AdvancedAxiosConfig) => {
    const url = parseQueryFilters("/settings", filters);
    const { data: res } = await apiGateway.get<{ data: SettingsEntity[] }>(url, options);

    return res.data;
  },
  getTrusted: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<TrustedList>("/businesses/trusted", options);

    return res;
  },
  getCategories: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<CategoriesList>("/categories", options);

    return res;
  },
  getCountriesList: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<{ data: Country[] }>("/countries", options);

    return res.data;
  },
  getCurrencies: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<{ data: Currency[] }>("/currencies", options);

    return res.data;
  },
};
