import { FC } from "react";

import { InfoBlock, MuiButton } from "@causevest/ui-kit";
import { Stack } from "@mui/material";
import clsx from "clsx";

import classes from "@lib/styles/campaigns.module.scss";
import { CategoriesList } from "@lib/types";

interface Props {
  categories: CategoriesList;
  onClick: (value: { category: string }) => void;
  category?: string;
}

export const CategoriesChecker: FC<Props> = ({ categories, onClick, category }) => (
  <InfoBlock title="Explore Causes">
    <Stack>
      <MuiButton
        className={clsx(classes.category, {
          [classes._active]: category === "all",
        })}
        onClick={() => onClick({ category: "all" })}
      >
        All
      </MuiButton>
      {categories?.data.map((cat, key) => (
        <MuiButton
          key={`category_${key}`}
          className={clsx(classes.category, {
            [classes._active]: cat.name === category,
          })}
          onClick={() => onClick({ category: cat.name })}
        >
          {cat.name}
        </MuiButton>
      ))}
    </Stack>
  </InfoBlock>
);
