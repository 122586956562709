"use client";

import { FC, useMemo } from "react";
import { Bar } from "react-chartjs-2";

import { FeedBlock } from "@components";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Props {
  className?: string;
}

export const SocialActivityChart: FC<Props> = ({ className }) => {
  const data = useMemo(
    () => ({
      labels: ["FB", "TW", "IN", "YT"],
      datasets: [
        {
          label: "Inbound",
          backgroundColor: "#00E8FE",
          borderWidth: 0,
          data: [20, 30, 40, 50],
        },
        {
          label: "Conversion",
          backgroundColor: "#125CD5",
          borderWidth: 0,
          data: [40, 50, 30, 20],
        },
      ],
    }),
    [],
  );

  return (
    <FeedBlock title="Social" className={className} isLight>
      <Bar
        data={data}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "bottom" as const,
            },
            title: {
              display: false,
            },
          },
          indexAxis: "y" as const,
          scales: {
            x: {
              beginAtZero: true,
              suggestedMin: 1,
              ticks: {
                callback: (_: unknown, value: number) => `${value * 10}k`,
              },
            },
          },
        }}
        redraw={false}
      />
    </FeedBlock>
  );
};
