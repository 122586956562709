import { FC } from "react";

import { Messages, StatisticPanel } from "@components";
import { Box, Stack } from "@mui/material";
import clsx from "clsx";

import { CreateCampaignSection } from "@features/campaign";

import { Campaign, CausesList } from "@lib/types";

import classes from "./RaiserView.module.scss";

interface Props {
  causes: CausesList;
  campaign: Campaign;
}

export const AsideStatisticPanel: FC<Props> = ({ causes, campaign }) => (
  <Stack className={classes.aside}>
    <Box className={clsx(classes.aside__createBtn, classes._desktop)}>
      <CreateCampaignSection causes={causes} />
    </Box>
    <StatisticPanel
      likes={campaign.votes}
      donors={campaign.donor_count}
      xcvCount={campaign.raised}
    />
    <Messages />
  </Stack>
);
