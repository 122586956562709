import { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import {
  ModalActionsField,
  MuiSelect,
  Preloader,
  TextArea,
  onInputErrorHandle,
} from "@causevest/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogContent, MenuItem, Stack } from "@mui/material";
import * as yup from "yup";

import { CampaignCreationModal } from "@features/campaign";

import { REQUIRED_MESSAGE } from "@lib/constants";
import common from "@lib/styles/common.module.scss";
import { Currency } from "@lib/types/models";

import classes from "./CampaignModal.module.scss";

const defaultValues = {
  country: "",
  address: "",
};

const schema = yup.object().shape({
  country: yup.string().required(REQUIRED_MESSAGE),
  currency: yup.string().required(REQUIRED_MESSAGE),
  address: yup.string(),
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FieldValues) => void;
  onPrev: () => void;
  currencies: Currency[];
  isLoading?: boolean;
}

export const SubscriptionFundsModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  onPrev,
  currencies,
  isLoading,
}) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues, currency: currencies[0].uuid },
    progressive: true,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onModalClose = () => {
    reset();
    onClose();
  };

  const onSaveData = (values: FieldValues) => {
    onSubmit(values);
    reset();
  };

  return (
    <CampaignCreationModal
      isOpen={isOpen}
      onClose={onClose}
      title="Fund Settings"
      hint="A few last details"
      step={2}
    >
      {isLoading ? (
        <Stack sx={{ width: "100%", height: "490px" }}>
          <Box sx={{ margin: "auto" }}>
            <Preloader />
          </Box>
        </Stack>
      ) : (
        <FormProvider {...methods}>
          <DialogContent>
            <Stack className={classes.wrapper}>
              <Stack className={classes.row}>
                <MuiSelect
                  name="currency"
                  id="currency"
                  label="Currency"
                  placeholderValue="Select Currency"
                  className={common.input}
                  error={!!errors.currency}
                  errorMessage={onInputErrorHandle(errors.currency?.message)}
                  required
                  fullWidth
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency.uuid} value={currency.uuid}>
                      {currency.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </Stack>
              <TextArea
                name="address"
                label="Address"
                placeholder="Type address here"
                rows={6}
                fullWidth
              />
            </Stack>
          </DialogContent>
          <ModalActionsField
            onClose={onModalClose}
            onSubmit={handleSubmit(onSaveData)}
            onPrev={onPrev}
            submitBtnText="Create Campaign"
          />
        </FormProvider>
      )}
    </CampaignCreationModal>
  );
};
