import { FC } from "react";

import { Stack, Typography } from "@mui/material";

import { getParsedUserName } from "@lib/helpers";
import { User } from "@lib/types";

import classes from "./AboutCreator.module.scss";

interface Props {
  owner: User;
}

export const UserInfo: FC<Props> = ({ owner }) => (
  <Stack flexDirection="row" alignItems="center" className={classes.heading}>
    <Typography className={classes.title}>{getParsedUserName(owner)}</Typography>
    {/*<SocialBar social={owner.social_profiles} />*/}
  </Stack>
);
