import React, { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { CancelButton, GradientButton, PreloaderSmall, TextArea } from "@causevest/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import * as yup from "yup";

import { REQUIRED_MESSAGE } from "@lib/constants";

interface Props {
  onSubmit: (values: FieldValues) => void;
  onClose: () => void;
  placeholder: string;
  isLoading: boolean;
}

const schema = yup.object().shape({
  content: yup.string().required(REQUIRED_MESSAGE),
});

export const CreateCommentForm: FC<Props> = ({ onSubmit, onClose, placeholder, isLoading }) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      content: "",
    },
    progressive: true,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onCancelComment = () => {
    reset();
    onClose();
  };

  const onSubmitComment = (values: FieldValues) => {
    onSubmit(values);
    reset();
  };

  if (isLoading) {
    return (
      <Stack justifyContent="center">
        <Stack sx={{ m: "auto" }}>
          <PreloaderSmall />
        </Stack>
      </Stack>
    );
  }

  return (
    <FormProvider {...methods}>
      <Stack gap="20px">
        <TextArea
          name="content"
          placeholder={placeholder}
          errorMessage={errors.content?.message}
          error={!!errors.content}
          fullWidth
          required
        />
        <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" gap="20px">
          <CancelButton onClick={onCancelComment}>Cancel</CancelButton>
          <GradientButton
            onClick={handleSubmit(onSubmitComment)}
            sx={{ width: "100px", height: "35px" }}
          >
            Save
          </GradientButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
