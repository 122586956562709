import { FC, ReactElement } from "react";

import { Icon } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";

import classes from "./EmptyCampaignsList.module.scss";

interface Props {
  children: ReactElement | string;
}

export const EmptyCampaignsList: FC<Props> = ({ children }) => (
  <Stack className={classes.wrapper}>
    <Stack className={classes.inner}>
      <Typography variant="h1" className={classes.title}>
        {children}
      </Typography>
      <Icon
        src="/images/no-campaigns.svg"
        alt="no campaigns picture"
        className={classes.image}
        sizes="100%"
        fill
      />
    </Stack>
  </Stack>
);
