import { FC } from "react";

import { GradientButton, MuiButton } from "@causevest/ui-kit";
import { Stack } from "@mui/material";

import classes from "./FiltersBar.module.scss";

interface Props {
  onSubmit: () => void;
  onExpandFilters: () => void;
  isExpanded: boolean;
  onReset: () => void;
}

export const FilterButtons: FC<Props> = ({ onSubmit, onExpandFilters, isExpanded, onReset }) => (
  <Stack flexDirection="row" alignItems="center" className={classes.filters}>
    <MuiButton variant="outlined" onClick={onExpandFilters}>
      {isExpanded ? "Show short filters" : "Show all filters"}
    </MuiButton>
    <MuiButton variant="outlined" onClick={onReset}>
      Clear All Filters
    </MuiButton>
    <GradientButton onClick={onSubmit} sx={{ pl: "25px", pr: "25px" }}>
      Submit filters
    </GradientButton>
  </Stack>
);
