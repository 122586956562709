"use client";

import { UseFormReturn, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ObjectShape } from "yup";

import { REQUIRED_MESSAGE } from "@lib/constants";

interface ReturnType {
  methods: UseFormReturn<Record<string | number, unknown>>;
  minTextCount: number;
}

const TEXTAREA_MIN_COUNT = 255;
const TEXTAREA_MAX_COUNT = 1024;

const schema = yup.object().shape({
  category: yup.string().required(REQUIRED_MESSAGE),
  title: yup.string().required(REQUIRED_MESSAGE),
  image: yup.string().required(REQUIRED_MESSAGE),
  short_description: yup
    .string()
    .min(TEXTAREA_MIN_COUNT, `Minimum ${TEXTAREA_MIN_COUNT} characters`)
    .max(
      TEXTAREA_MAX_COUNT,
      `Short description length must not be greater than ${TEXTAREA_MAX_COUNT} characters`,
    )
    .required(REQUIRED_MESSAGE),
});

export const useCampaignInfo = (
  extendedSchema?: ObjectShape,
  extendedDefaultValues?: Record<string, string>,
): ReturnType => {
  const currentSchema = extendedSchema ? schema.shape(extendedSchema) : schema;
  const methods = useForm({
    resolver: yupResolver(currentSchema),
    defaultValues: {
      category: "",
      title: "",
      short_description: "",
      image: "",
      ...extendedDefaultValues,
    },
  });

  return {
    methods,
    minTextCount: TEXTAREA_MIN_COUNT,
  };
};
