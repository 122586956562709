"use client";

import { FC } from "react";

import { GradientButton, Icon, SecondaryButton, useElementOpen } from "@causevest/ui-kit";
import { DonateRejectPopup } from "@components";
import { Box, Stack } from "@mui/material";
import clsx from "clsx";

import { DonationSection } from "@features/donation";

import { Campaign } from "@lib/types";

import classes from "./DonateBtnsGroup.module.scss";

interface Props {
  campaign: Partial<Campaign>;
  isRaiserView?: boolean;
}

export const DonateBtnsGroup: FC<Props> = ({ campaign, isRaiserView }) => {
  const { isOpen, open, close } = useElementOpen(false);

  return (
    <Stack className={clsx(classes.wrapper, { [classes._isRaiser]: isRaiserView })}>
      <Stack
        className={clsx(classes.bottomBtns, {
          [classes._isRaiser]: isRaiserView,
        })}
        sx={{ order: isRaiserView ? 2 : 1 }}
      >
        <DonationSection
          campaign={campaign}
          sx={{ order: isRaiserView ? 2 : 1, width: "170px" }}
          className={clsx(classes.bottomBtns__btn, {
            [classes._isRaiser]: isRaiserView,
          })}
        />
        <GradientButton
          sx={{ width: "125px" }}
          className={clsx(classes.bottomBtns__btn, {
            [classes._isRaiser]: isRaiserView,
          })}
        >
          <Icon width={16} height={14} src="/images/share.svg" alt="share icon" />
          <span>Share</span>
        </GradientButton>
      </Stack>
      <Stack flexDirection="row" alignItems="center" className={classes.bottomBtns}>
        <SecondaryButton
          variant="outlined"
          className={clsx(classes.bottomBtns__btn, classes._secondary)}
          sx={{ width: "125px" }}
          onClick={open}
        >
          <Stack flexDirection="row" alignItems="center" className={classes.secondaryInner}>
            <Icon src="/images/heart.svg" width={14} height={12} alt="favourite icon" />
            <span>Vote</span>
            <Box className={classes.secondaryInner__divider} />
            <span className={classes.secondaryInner__count}>100</span>
          </Stack>
        </SecondaryButton>
        <SecondaryButton
          variant="outlined"
          className={clsx(classes.bottomBtns__btn, classes._secondary)}
          sx={{ width: "170px" }}
          onClick={open}
        >
          <Stack flexDirection="row" alignItems="center" className={classes.secondaryInner}>
            <Icon src="/images/heart.svg" width={14} height={12} alt="favourite icon" />
            <span>XCV Votes</span>
            <Box className={classes.secondaryInner__divider} />
            <span className={classes.secondaryInner__count}>100</span>
          </Stack>
        </SecondaryButton>
      </Stack>
      <DonateRejectPopup isOpen={isOpen} onClose={close} />
    </Stack>
  );
};
