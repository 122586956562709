"use client";

import React, { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { MuiSelect, useEffectAsync } from "@causevest/ui-kit";
import { Box, LinearProgress, MenuItem } from "@mui/material";
import { AxiosError } from "axios";
import clsx from "clsx";

import { proxyAPIGateway } from "@api";

import { handleErrorToast } from "@lib/helpers";
import { CampaignStatus, ErrorUnprocessable } from "@lib/types";

import classes from "./RaiserInner.module.scss";

interface Props {
  uuid: string;
  status: string;
  label?: string;
}

export const CampaignStatusInput: FC<Props> = ({ uuid, status, label }) => {
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({
    defaultValues: {
      status,
    },
  });
  const { watch } = methods;
  const currentStatus = watch("status");

  useEffectAsync(
    async (awaiter) => {
      if (currentStatus !== status) {
        setIsLoading(true);
        try {
          await awaiter(
            proxyAPIGateway.patch("/campaign/update", {
              uuid,
              status: currentStatus as CampaignStatus,
            }),
          );
        } catch (err) {
          handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [currentStatus, status],
  );

  if (isLoading) {
    return (
      <Box sx={{ mt: "15px" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <MuiSelect
        name="status"
        id="status"
        placeholderValue="Set campaign status"
        label={label}
        className={clsx(classes.select, classes[`_${currentStatus}`])}
      >
        <MenuItem value="draft" sx={{ color: "#FE4949" }}>
          Draft
        </MenuItem>
        <MenuItem value="active" sx={{ color: "#33A853" }}>
          Active
        </MenuItem>
      </MuiSelect>
    </FormProvider>
  );
};
