"use client";

import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Box, FormControlLabel, InputLabel, RadioGroup, Stack } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";
import clsx from "clsx";

import { BpRadio } from "./BpRadio";
import classes from "./Switcher.module.scss";

interface Props {
  name: string;
  items: [string, string];
  label?: string;
  inlined?: boolean;
  centered?: boolean;
  className?: string;
  sx?: SxProps;
}

export const Switcher: FC<Props> = ({ name, items, label, inlined, centered, className, sx }) => {
  const { control } = useFormContext();

  return (
    <Stack
      flexDirection={inlined ? "row" : "column"}
      alignItems={inlined || centered ? "center" : "flex-start"}
      gap={inlined ? "20px" : 0}
      className={className}
      sx={sx}
    >
      {!!label && <InputLabel sx={{ fontSize: "14px", mb: "5px" }}>{label}</InputLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} name={name} sx={{ gap: "20px" }} row>
            <Box
              className={clsx(classes.chip, {
                [classes._active]: field.value === "false",
              })}
            >
              <FormControlLabel
                value="false"
                control={<BpRadio />}
                label={items[0]}
                className={classes.chip__inner}
              />
            </Box>
            <Box
              className={clsx(classes.chip, {
                [classes._active]: field.value === "true",
              })}
            >
              <FormControlLabel
                value="true"
                control={<BpRadio />}
                label={items[1]}
                className={classes.chip__inner}
              />
            </Box>
          </RadioGroup>
        )}
      />
    </Stack>
  );
};
