import { FC } from "react";

import { Preloader, useEffectAsync } from "@causevest/ui-kit";
import { Box, Stack } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { AxiosError } from "axios";
import clsx from "clsx";

import { handleErrorToast } from "@lib/helpers";
import { AppRoutes, ErrorUnprocessable, User } from "@lib/types";

import classes from "./TiersListModal.module.scss";

interface Props {
  owner: Partial<User>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  isStripePayment: boolean;
  setSuccessOpen: () => void;
}

export const TierPaymentElement: FC<Props> = ({
  owner,
  isLoading,
  setIsLoading,
  isStripePayment,
  setSuccessOpen,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "auto",
  };

  const onPaymentSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    try {
      setIsLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${process.env.ORIGIN_URL}/${AppRoutes.CAMPAIGNS}`,
          payment_method_data: {
            billing_details: {
              email: owner.email,
              name: owner.display_name,
              address: {
                country: owner.address?.country?.name,
                postal_code: owner.address?.postal_code,
              },
            },
          },
        },
        redirect: "if_required",
      });

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          handleErrorToast(error.message || "An error occurred.");
        } else {
          handleErrorToast("An unexpected error occurred.");
        }
      } else {
        setSuccessOpen();
      }
    } catch (err) {
      handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
    } finally {
      setIsLoading(false);
    }
  };

  useEffectAsync(
    async (awaiter) => {
      if (isStripePayment) {
        await awaiter(onPaymentSubmit());
      }
    },
    [isStripePayment],
  );

  return (
    <>
      {isLoading && (
        <Stack sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ m: "auto" }}>
            <Preloader />
          </Box>
        </Stack>
      )}
      <Box className={clsx(classes.payment, { [classes._isLoading]: isLoading })}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      </Box>
    </>
  );
};
