"use client";

import { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { Box, Stack, Typography } from "@mui/material";
import clsx from "clsx";

import { useSession } from "@contexts";

import common from "@lib/styles/common.module.scss";

import classes from "./FundsStatistic.module.scss";

export const FundsStatistic: FC = () => {
  const { user } = useSession();

  if (!user) {
    return null;
  }

  return (
    <Stack className={clsx(classes.wrapper, common.shadowBlock)}>
      <Box className={common.barFlowerBg}>
        <Icon src="/images/palm.svg" alt="palm icon" width={128} height={174} />
      </Box>
      <Stack>
        <Typography className={classes.count}>{`$ ${user.total_raised ?? 0}`}</Typography>
        <Typography className={classes.title}>Funds Raised</Typography>
      </Stack>
      <Box className={classes.divider} />
      <Stack>
        <Typography className={classes.count}>{`$ ${user.operator_income_month ?? 0}`}</Typography>
        <Typography className={classes.title}>Operator Income per month</Typography>
      </Stack>
      <Box className={classes.divider} />
      <Stack>
        <Typography className={classes.count}>{`$ ${user.available_balance ?? 0}`}</Typography>
        <Typography className={classes.title}>Available Balance</Typography>
      </Stack>
    </Stack>
  );
};
