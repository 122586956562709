"use client";

import { ChangeEvent, FC, useState } from "react";
import { FieldValues } from "react-hook-form";

import { useEffectAsync } from "@causevest/ui-kit";
import { useEntitiesList } from "@components";
import { Box, Container, Stack } from "@mui/material";

import { campaignsAPI } from "@api";

import {
  CampaignsPagination,
  CategoriesChecker,
  FiltersBar,
  CampaignsList as ListOfCampaigns,
} from "@features/campaigns";

import classes from "@lib/styles/campaigns.module.scss";
import { CampaignsList, CategoriesList } from "@lib/types";

interface Props {
  categories: CategoriesList;
  initialCampaigns: CampaignsList;
  currentCategory: string;
}

const CARDS_PER_PAGE = 6;

export const CampaignsInner: FC<Props> = ({
  categories,
  initialCampaigns,
  currentCategory = "all",
}) => {
  const {
    isLoading,
    getEntitiesList,
    list: campaigns,
  } = useEntitiesList(campaignsAPI.getCampaigns, initialCampaigns);
  const [category, setCategory] = useState(currentCategory);

  const onCampaignsListUpdate = async (values: FieldValues) => {
    setCategory(values.category);
    await getEntitiesList({}, { ...values, per_page: CARDS_PER_PAGE, sort: "-created_at" });
  };

  const onSetPagination = async (_: ChangeEvent<unknown>, page: number) => {
    await getEntitiesList({}, { page, category, per_page: CARDS_PER_PAGE, sort: "-created_at" });
  };

  useEffectAsync(
    async (awaiter) => {
      if (!!currentCategory && currentCategory !== "all") {
        await awaiter(
          getEntitiesList(
            {},
            { category: currentCategory, per_page: CARDS_PER_PAGE, sort: "-created_at" },
          ),
        );
      }
    },
    [currentCategory],
  );

  return (
    <>
      <FiltersBar categories={categories.data} onHandleFilters={onCampaignsListUpdate} />
      <Box className={classes.campaigns__wrapper}>
        <Container>
          <Stack flexDirection="row" className={classes.campaignsList}>
            <Box className={classes.aside}>
              <CategoriesChecker
                categories={categories}
                onClick={onCampaignsListUpdate}
                category={category}
              />
            </Box>
            <Stack className={classes.campaignsList__inner}>
              <ListOfCampaigns campaigns={campaigns?.data} isLoading={isLoading} />
              {!isLoading && (
                <>
                  <CampaignsPagination
                    color="primary"
                    count={campaigns?.meta.last_page}
                    page={campaigns?.meta.current_page}
                    onChange={onSetPagination}
                    className={classes._pDesktop}
                  />
                  <CampaignsPagination
                    color="primary"
                    count={campaigns?.meta.last_page}
                    page={campaigns?.meta.current_page}
                    onChange={onSetPagination}
                    className={classes._pMobile}
                    isMobile
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
