"use client";

import { FC, useLayoutEffect, useState } from "react";

import { SliderArrowsGroup, SwiperSlider, useSwiperRef } from "@causevest/ui-kit";
import { Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import { Swiper } from "swiper/types";

import { TierInModal } from "@features/campaign/TiersListModal/TierInModal";

import { Tier, User } from "@lib/types";

import classes from "./TiersListModal.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  current: number;
  owner: Partial<User>;
  setSuccessOpen?: (open: boolean) => void;
  tiers?: Tier[];
}

export const TiersListModal: FC<Props> = ({
  isOpen,
  onClose,
  current,
  tiers,
  owner,
  setSuccessOpen,
}) => {
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();
  const [currentSlide, setCurrentSlide] = useState(current);
  const [swiperInstance, setSwiperInstance] = useState<Swiper>();

  const onSlideChange = (swiper: Swiper) => {
    setCurrentSlide(swiper.activeIndex);
  };

  const onDialogClose = () => {
    if (swiperInstance) {
      swiperInstance.setProgress(0, 0);
    }

    onClose();
  };

  useLayoutEffect(() => {
    if (swiperInstance) {
      swiperInstance.setProgress(current, 0);
    }
  }, [current]); // eslint-disable-line

  if (!tiers?.length) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onDialogClose} disablePortal keepMounted>
      <DialogTitle sx={{ p: 0 }}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          className={classes.heading}
        >
          <Typography
            className={classes.title}
          >{`Support: ${tiers[currentSlide].name}`}</Typography>
          <SliderArrowsGroup prevBtnRef={prevElRef} nextBtnRef={nextElRef} sizes={24} />
        </Stack>
      </DialogTitle>
      <SwiperSlider
        prevEl={prevEl}
        nextEl={nextEl}
        onInit={(swiper) => setSwiperInstance(swiper)}
        spaceBetween={24}
        initialSlide={currentSlide}
        onActiveIndexChange={onSlideChange}
      >
        {tiers.map((tier, key) => (
          <TierInModal
            key={tier.uuid}
            tier={tier}
            onClose={onDialogClose}
            owner={owner}
            isOpen={isOpen}
            idx={key}
            setSuccessOpen={setSuccessOpen}
          />
        ))}
      </SwiperSlider>
    </Dialog>
  );
};
