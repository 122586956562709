"use client";

import { FC, MouseEvent, useState } from "react";

import { Box, LinearProgress, SxProps, ToggleButton, ToggleButtonGroup } from "@mui/material";
import clsx from "clsx";

import { proxyAPIGateway } from "@api";

import { useSession } from "@contexts";

import { UserTypes } from "@lib/types";

import classes from "./UserTypeSection.module.scss";

interface Props {
  isFull?: boolean;
  sx?: SxProps;
}

export const UserTypeSection: FC<Props> = ({ isFull, sx }) => {
  const { user, setUserData } = useSession();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (_: MouseEvent<HTMLElement>, newType: string) => {
    setIsLoading(true);
    await proxyAPIGateway.post("/user/type", { type: newType });
    setUserData({ type: newType as UserTypes });
    setIsLoading(false);
  };

  if (!user?.type || isLoading) {
    return (
      <Box sx={sx} className={clsx(classes.wrapper, { [classes._full]: isFull })}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box sx={sx} className={clsx(classes.wrapper, { [classes._full]: isFull })}>
      <ToggleButtonGroup
        color="primary"
        value={user.type}
        onChange={handleChange}
        className={clsx(classes.inner, { [classes._full]: isFull })}
        exclusive
      >
        <ToggleButton
          className={clsx(
            classes.item,
            { [classes._full]: isFull },
            { [classes._disabled]: user.type === UserTypes.DONOR },
          )}
          value={UserTypes.DONOR}
        >
          Donor
        </ToggleButton>
        <ToggleButton
          className={clsx(
            classes.item,
            { [classes._full]: isFull },
            { [classes._disabled]: user.type === UserTypes.RAISER },
          )}
          value={UserTypes.RAISER}
        >
          Raiser
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
