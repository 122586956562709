"use client";

import { FC } from "react";

import { CampaignProvider, RaiserInner } from "@features/campaign";

import { Campaign } from "@lib/types";

interface Props {
  campaign: Campaign;
}

export const RaiserCampaignWrapper: FC<Props> = ({ campaign }) => (
  <CampaignProvider>
    <RaiserInner campaign={campaign} />
  </CampaignProvider>
);
