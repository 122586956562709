import { FC, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";

import { GradientButton, useElementOpen } from "@causevest/ui-kit";
import { SxProps } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AxiosError } from "axios";

import { proxyAPIGateway } from "@api";

import { DonationContextProvider } from "@features/donation/DonationContext";
import { StepThreeModal } from "@features/donation/modals/StepThreeModal";
import { StepTwoModal } from "@features/donation/modals/StepTwoModal";

import { useCommonDataContext } from "@contexts";

import { handleErrorToast } from "@lib/helpers";
import { Campaign, ErrorUnprocessable } from "@lib/types";

import StepOneModal from "./modals/StepOneModal";

interface Props {
  campaign: Partial<Campaign>;
  initialAmount?: number;
  sx?: SxProps;
  className?: string;
}

export const DonationSection: FC<Props> = ({ campaign, initialAmount, sx, className }) => {
  const { stripeKey } = useCommonDataContext();
  const { open: openStepOne, close: closeStepOne, isOpen: isStepOneOpen } = useElementOpen(false);
  const { open: openStepTwo, close: closeStepTwo, isOpen: isStepTwoOpen } = useElementOpen(false);
  const {
    open: openStepThree,
    close: closeStepThree,
    isOpen: isStepThreeOpen,
  } = useElementOpen(false);
  const [clientSecret, setClientSecret] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentAmount, setCurrentAmount] = useState<number>(
    initialAmount ?? (campaign.goal ? +campaign.goal.amount : 0),
  );

  const stripePromise = useMemo(() => loadStripe(stripeKey), [stripeKey]);

  const onPaymentIntent = async ({ amount }: FieldValues) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await proxyAPIGateway.post("/payments/intent", {
        amount,
        campaign_goal_id: campaign.goal?.uuid,
      });

      if (data.client_secret) {
        setCurrentAmount((prevState) => prevState + amount);
        setClientSecret(data.client_secret);
        closeStepOne();
        openStepTwo();
      }
    } catch (err) {
      handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
    } finally {
      setIsLoading(false);
    }
  };

  const onHitThirdModal = () => {
    closeStepTwo();
    openStepThree();
  };

  return (
    <DonationContextProvider>
      <Elements key={clientSecret} options={{ clientSecret }} stripe={stripePromise}>
        <GradientButton sx={{ height: "35px", ...sx }} onClick={openStepOne} className={className}>
          Donate Now
        </GradientButton>
        <StepOneModal
          isOpen={isStepOneOpen}
          handleClose={closeStepOne}
          campaign={campaign}
          initialAmount={currentAmount}
          onSubmit={onPaymentIntent}
          isLoading={isLoading}
        />
        <StepTwoModal
          campaign={campaign}
          isOpen={isStepTwoOpen}
          handleClose={closeStepTwo}
          isLoading={isLoading}
          initialAmount={currentAmount}
          setIsLoading={setIsLoading}
          onSubmit={onHitThirdModal}
        />
        <StepThreeModal isOpen={isStepThreeOpen} handleClose={closeStepThree} campaign={campaign} />
      </Elements>
    </DonationContextProvider>
  );
};
