"use client";

import { useRouter } from "next/navigation";

import { FC, useState } from "react";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";

import { AxiosError, AxiosResponse } from "axios";

import { proxyAPIGateway } from "@api";

import {
  CampaignCreationWelcomeModal,
  CampaignFundsModal,
  CampaignInformationModal,
  CampaignSettingsModal,
  CausesListModal,
  CreateCauseModal,
  SubscriptionInformationModal,
  SubscriptionTiersModal,
  useCampaignContext,
} from "@features/campaign";

import { useSession } from "@contexts";

import { handleErrorToast } from "@lib/helpers";
import { AppRoutes, CampaignGoal, CausesList, ErrorUnprocessable, UserTypes } from "@lib/types";

const campaignTypeItems = Object.freeze([
  {
    image: "/images/creation-1.svg",
    title: "Monetary Goal",
    hint: "One off where you raise a set amount of money for a set period of time or target amount",
    choice: false,
  },
  {
    image: "/images/creation-2.svg",
    title: "Subscription",
    hint: "Subscriber based fundraising where donations are made on a regular, ongoing basis",
    choice: true,
  },
]);

const causeChoiceItems = Object.freeze([
  {
    image: "/images/select-goal-1.svg",
    title: "Fundraising For Someone else",
    hint: "All donations go directly to the cause. As an operator, you get a percentage of the total funds raised",
    choice: false,
  },
  {
    image: "/images/select-goal-2.svg",
    title: "Fundraising For Yourself",
    hint: "All donations go directly to your bank account",
    choice: true,
  },
]);

interface Props {
  causes: CausesList;
}

export const NewCampaignModals: FC<Props> = ({ causes }) => {
  const [isLoading, setLoading] = useState(false);
  const { newCampaign, modals, changeModals, setCampaignData, cleanup } = useCampaignContext();
  const { user, setUserData } = useSession();
  const router = useRouter();

  const onCreateCampaign = async ({
    hide_balance,
    tiers,
    toEndCampaign,
    ...values
  }: FieldValues) => {
    setLoading(true);
    try {
      const type = values.is_subscription
        ? CampaignGoal.OPEN
        : toEndCampaign === "true"
          ? CampaignGoal.TIMED
          : CampaignGoal.FINANCIAL;

      await proxyAPIGateway.post("/campaign/create", {
        ...newCampaign,
        ...values,
        amount: 0,
        hide_balance: hide_balance ? hide_balance === "true" : undefined,
        type,
      });

      toast.success("Campaign was successfully created", {
        theme: "colored",
      });

      setUserData({
        type: UserTypes.RAISER,
        total_campaigns: (user?.total_campaigns ?? 0) + 1,
        ...(values.is_subscription ? { has_subscription: values.is_subscription } : {}),
      });
      changeModals({
        isTiersModalOpen: false,
        isCampaignSettingsOpen: false,
      });
      router.push(AppRoutes.DASHBOARD);
    } catch (err) {
      handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
    } finally {
      cleanup();
      setLoading(false);
    }
  };

  const closeWelcomeModal = () => {
    changeModals({ isCampaignTypeScreenOpen: false });
  };

  const closeCauseChoice = () => {
    changeModals({ isCauseChoiceOpen: false });
  };

  const closeCausesList = () => {
    changeModals({ isCausesListOpen: false });
  };

  const closeCampaignInformation = () => {
    changeModals({ isCampaignInformationOpen: false });
  };

  const closeSubscriptionInformation = () => {
    changeModals({ isSubscriptionInformationOpen: false });
  };

  const closeFundsModal = () => {
    changeModals({ isFundsModalOpen: false });
  };

  const closeCampaignSettings = () => {
    changeModals({ isCampaignSettingsOpen: false });
  };

  const closeCauseCreation = () => {
    changeModals({ isCreateCauseOpen: false });
  };

  const closeTiersModal = () => {
    changeModals({ isTiersModalOpen: false });
  };

  const onBackToInfoModal = () => {
    const backPopup = newCampaign?.is_subscription
      ? { isSubscriptionInformationOpen: true }
      : { isCampaignInformationOpen: true };

    changeModals({
      isTiersModalOpen: false,
      isFundsModalOpen: false,
      ...backPopup,
    });
  };

  const onBackToFundsModal = () => {
    changeModals({ isFundsModalOpen: true, isCampaignSettingsOpen: false });
  };

  const backToCampaignTypes = () => {
    changeModals({
      isCauseChoiceOpen: false,
      isSubscriptionInformationOpen: false,
      isCampaignTypeScreenOpen: true,
    });
  };

  const backToCauseChoice = () => {
    const fallbackFlag = newCampaign?.cause
      ? { isCausesListOpen: true }
      : { isCauseChoiceOpen: true };

    changeModals({
      isCampaignInformationOpen: false,
      ...fallbackFlag,
    });
  };

  const openCauseChoice = (isSubscription: boolean) => {
    setCampaignData({
      is_subscription: isSubscription,
      type: isSubscription ? CampaignGoal.OPEN : CampaignGoal.FINANCIAL,
    });

    if (isSubscription) {
      changeModals({
        isCampaignTypeScreenOpen: false,
        isSubscriptionInformationOpen: true,
      });
    } else {
      changeModals({
        isCampaignTypeScreenOpen: false,
        isCauseChoiceOpen: true,
      });
    }
  };

  const followNextStep = (isSelfFundraising: boolean) => {
    const fundraisingChoice = isSelfFundraising
      ? { isCampaignInformationOpen: true }
      : { isCausesListOpen: true };

    changeModals({
      isCauseChoiceOpen: false,
      ...fundraisingChoice,
    });
  };

  const onHitFirstStep = () => {
    const secondStepType = newCampaign?.is_subscription
      ? { isTiersModalOpen: true }
      : { isFundsModalOpen: true };

    changeModals({
      isCampaignInformationOpen: false,
      isSubscriptionInformationOpen: false,
      ...secondStepType,
    });
  };

  const onHitSecondStep = () => {
    changeModals({
      isFundsModalOpen: false,
      isCampaignSettingsOpen: true,
    });
  };

  const onCauseSupport = (uuid: string) => {
    setCampaignData({ cause: uuid });
    changeModals({
      isCausesListOpen: false,
      isCampaignInformationOpen: true,
    });
  };

  const handleCauseCreation = () => {
    changeModals({ isCausesListOpen: false, isCreateCauseOpen: true });
  };

  const onCreateCause = async (values: FieldValues) => {
    setLoading(true);
    try {
      const res: AxiosResponse = await proxyAPIGateway.post("/cause/create", values);

      if (res.data.uuid) {
        setCampaignData({ cause: res.data.uuid });
        toast.success("Cause was successfully created", {
          theme: "colored",
        });
      }
    } catch (err) {
      handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
    } finally {
      setLoading(false);
      changeModals({
        isCreateCauseOpen: false,
        isCampaignInformationOpen: true,
      });
    }
  };

  return (
    <>
      <CampaignCreationWelcomeModal
        isOpen={modals.isCampaignTypeScreenOpen}
        handleClose={closeWelcomeModal}
        onSubmit={openCauseChoice}
        title="What type of Campaign would you like?"
        items={campaignTypeItems}
      />
      <CampaignCreationWelcomeModal
        isOpen={modals.isCauseChoiceOpen}
        handleClose={closeCauseChoice}
        onSubmit={followNextStep}
        onPrev={backToCampaignTypes}
        title="Who is this Campaign for?"
        items={causeChoiceItems}
      />
      <CausesListModal
        isOpen={modals.isCausesListOpen}
        handleClose={closeCausesList}
        causes={causes}
        onSubmit={handleCauseCreation}
        onCauseSupport={onCauseSupport}
      />
      <CreateCauseModal
        isOpen={modals.isCreateCauseOpen}
        handleClose={closeCauseCreation}
        onSubmit={onCreateCause}
        isLoading={isLoading}
      />
      <CampaignInformationModal
        isOpen={modals.isCampaignInformationOpen}
        onClose={closeCampaignInformation}
        onSubmit={onHitFirstStep}
        onPrev={backToCauseChoice}
      />
      <SubscriptionInformationModal
        isOpen={modals.isSubscriptionInformationOpen}
        onClose={closeSubscriptionInformation}
        onSubmit={onHitFirstStep}
        onPrev={backToCampaignTypes}
      />
      <CampaignFundsModal
        isOpen={modals.isFundsModalOpen}
        onClose={closeFundsModal}
        onSubmit={onHitSecondStep}
        onPrev={onBackToInfoModal}
      />
      <SubscriptionTiersModal
        isOpen={modals.isTiersModalOpen}
        onClose={closeTiersModal}
        onSubmit={onCreateCampaign}
        onPrev={onBackToInfoModal}
        isLoading={isLoading}
      />
      <CampaignSettingsModal
        isOpen={modals.isCampaignSettingsOpen}
        onClose={closeCampaignSettings}
        onPrev={onBackToFundsModal}
        onSubmit={onCreateCampaign}
        isLoading={isLoading}
      />
    </>
  );
};
