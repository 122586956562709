"use client";

import React, { FC, ReactElement } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import clsx from "clsx";

import { ModalActionsField } from "../ModalActionsField";
import classes from "./Dialog.module.scss";
import { DialogFormWrapper } from "./DialogFormWrapper";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  children: ReactElement | ReactElement[];
  onSubmit?: (values: FieldValues) => void;
  title?: string;
  titleActionBlock?: ReactElement;
  titleClass?: string;
  titleContent?: ReactElement;
  isCentered?: boolean;
  methods?: UseFormReturn;
  submitBtnText?: string;
  bottomContent?: ReactElement;
  disablePortal?: boolean;
}

export const MuiDialog: FC<Props> = ({
  isOpen,
  handleClose,
  onSubmit,
  title,
  titleClass,
  titleActionBlock,
  titleContent,
  isCentered,
  children,
  methods,
  submitBtnText,
  bottomContent,
  disablePortal = false,
}) => (
  <Dialog open={isOpen} onClose={handleClose} disablePortal={disablePortal}>
    <DialogFormWrapper methods={methods}>
      <>
        {!!title && (
          <DialogTitle
            className={clsx(classes.title, {
              [classes._centered]: isCentered,
            })}
          >
            <Stack
              className={clsx(
                classes.title__inner,
                { [classes._centered]: isCentered },
                titleClass,
              )}
            >
              {title}
              <Stack flexDirection="row" alignItems="center" gap="10px">
                {titleActionBlock}
                <IconButton onClick={handleClose} className={classes.close}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>
            {titleContent}
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
        {!!methods && onSubmit && (
          <ModalActionsField
            onClose={handleClose}
            onSubmit={methods.handleSubmit(onSubmit)}
            submitBtnText={submitBtnText}
          />
        )}
      </>
    </DialogFormWrapper>
    {bottomContent}
  </Dialog>
);
