import { FC } from "react";
import { FieldValues } from "react-hook-form";

import { Preloader } from "@causevest/ui-kit";
import { Stack } from "@mui/material";

import { CampaignCreationModal, CreateTierForm } from "@features/campaign";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FieldValues) => Promise<void>;
  onPrev: () => void;
  isLoading: boolean;
}

export const SubscriptionTiersModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  onPrev,
  isLoading,
}) => (
  <CampaignCreationModal
    isOpen={isOpen}
    onClose={onClose}
    title="Create a Tier"
    hint="You’ll be able to create more tiers later on!"
    stepsNum={2}
    step={1}
  >
    {isLoading ? (
      <Stack sx={{ width: "100%", height: "490px" }}>
        <Preloader />
      </Stack>
    ) : (
      <CreateTierForm onSubmit={onSubmit} onClose={onClose} onPrev={onPrev} />
    )}
  </CampaignCreationModal>
);
