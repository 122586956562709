import { FC } from "react";
import { FieldValues } from "react-hook-form";

import { Dialog, DialogTitle, Typography } from "@mui/material";

import { CreateTierForm } from "@features/campaign";

import { Tier } from "@lib/types";

import classes from "./CampaignModal.module.scss";

interface Props {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (values: FieldValues) => Promise<void>;
  tier?: Partial<Tier>;
}

export const CreateTierModal: FC<Props> = ({ isOpen, title, tier, onClose, onSubmit }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle sx={{ p: 0 }}>
      <Typography className={classes.heading}>{title}</Typography>
    </DialogTitle>
    <CreateTierForm onSubmit={onSubmit} onClose={onClose} tier={tier} submitBtnText="Save" />
  </Dialog>
);
