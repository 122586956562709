"use client";

import { FC, Suspense, useMemo, useState } from "react";

import { Preloader, Tabs } from "@causevest/ui-kit";
import { Box, Stack } from "@mui/material";

import { Campaign } from "@lib/types";

import classes from "./RaiserCampaignMenu.module.scss";
import { RaiserCampaignMenuInner } from "./RaiserCampaignMenuInner";

interface Props {
  campaign: Campaign;
}

export const RaiserCampaignMenu: FC<Props> = ({ campaign }) => {
  const [tab, setTab] = useState(0);

  const tabs = useMemo(() => {
    if (campaign.is_subscription) {
      return ["Statistics", "Edit Campaign", "Manage Tiers", "F.A.Q.", "Threads"];
    }

    return ["Statistics", "Edit Campaign", "Settings", "Manage Rewards", "F.A.Q.", "Threads"];
  }, [campaign.is_subscription]);

  return (
    <Stack className={classes.menu}>
      <Tabs values={tabs} onChange={(idx) => setTab(idx)} />
      <Box className={classes.menu__inner}>
        <Suspense
          fallback={
            <Stack justifyContent="space-around" sx={{ minHeight: "calc(100vh - 410px)" }}>
              <Preloader />
            </Stack>
          }
        >
          <RaiserCampaignMenuInner isSubscription={campaign.is_subscription} tab={tab} />
        </Suspense>
      </Box>
    </Stack>
  );
};
