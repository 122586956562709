"use client";

import { FC, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { MuiSelect, TextInput } from "@causevest/ui-kit";
import { Box, Container, MenuItem, Stack } from "@mui/material";
import clsx from "clsx";

import { Category } from "@lib/types";

import { FilterButtons } from "./FilterButtons";
import classes from "./FiltersBar.module.scss";

interface Props {
  categories: Category[];
  onHandleFilters: (values: FieldValues) => void;
}

const defaultValues = {
  status: "",
  goal: "",
  sort: "",
  category: "",
  title: "",
  description: "",
  cause: "",
  cause_description: "",
  business: "",
  user: "",
  country: "",
  currency: "",
};

export const FiltersBar: FC<Props> = ({ categories, onHandleFilters }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, reset } = methods;
  const [isFullyExpanded, setFullyExpanded] = useState(false);

  const onExpandFilters = () => setFullyExpanded((prev) => !prev);

  const onResetFilters = () => reset(defaultValues);

  return (
    <FormProvider {...methods}>
      <Box className={classes.filtersWrapper} id="campaigns-list">
        <Container>
          <Stack className={classes.filtersInner}>
            <Stack flexDirection="row" alignItems="center" className={classes.filters}>
              <MuiSelect
                name="status"
                id="status"
                className={classes.select}
                placeholderValue="Filter by status"
                fullWidth
              >
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="active">Active</MenuItem>
              </MuiSelect>
              <MuiSelect
                name="goal"
                id="goal"
                className={classes.select}
                placeholderValue="Filter by goal"
                fullWidth
              >
                <MenuItem value="timed">Timed</MenuItem>
                <MenuItem value="financial">Financial</MenuItem>
                <MenuItem value="open-ended">Open-Ended</MenuItem>
              </MuiSelect>
              <MuiSelect
                name="sort"
                id="sort"
                className={classes.select}
                placeholderValue="Sort By"
                fullWidth
              >
                <MenuItem value="title">Title</MenuItem>
                <MenuItem value="status">Status</MenuItem>
                <MenuItem value="created_at">Created At</MenuItem>
                <MenuItem value="goal_amount">Goal Amount</MenuItem>
                <MenuItem value="owner">Owner</MenuItem>
                <MenuItem value="category">Category</MenuItem>
                <MenuItem value="currency">Currency</MenuItem>
              </MuiSelect>
              <Box className={classes.mobileAside}>
                <MuiSelect
                  name="category"
                  id="category"
                  className={classes.select}
                  placeholderValue="Select Category"
                  extraOption={<MenuItem value="all">All</MenuItem>}
                  fullWidth
                >
                  {categories.map((category, key) => (
                    <MenuItem key={`sort_category_${key}`} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </Box>
            </Stack>
            <Box className={clsx(classes.btns, classes._desktop)}>
              <FilterButtons
                onSubmit={handleSubmit(onHandleFilters)}
                onExpandFilters={onExpandFilters}
                isExpanded={isFullyExpanded}
                onReset={onResetFilters}
              />
            </Box>
          </Stack>
        </Container>
        {isFullyExpanded && (
          <Container className={classes.cut}>
            <Stack flexDirection="row" alignItems="center" className={classes.cut__inner}>
              <TextInput
                name="title"
                placeholder="Filter by title"
                className={classes.input}
                wrapperClass={classes.input__wrapper}
                fullWidth
              />
              <TextInput
                name="description"
                placeholder="Filter by description"
                className={classes.input}
                wrapperClass={classes.input__wrapper}
                fullWidth
              />
              <TextInput
                name="cause"
                placeholder="Filter by cause"
                className={classes.input}
                wrapperClass={classes.input__wrapper}
                fullWidth
              />
              <TextInput
                name="cause_description"
                placeholder="Filter by cause description"
                className={classes.input}
                wrapperClass={classes.input__wrapper}
                fullWidth
              />
              <TextInput
                name="business"
                placeholder="Filter by business name"
                className={classes.input}
                wrapperClass={classes.input__wrapper}
                fullWidth
              />
              <TextInput
                name="user"
                placeholder="Filter by user name"
                className={classes.input}
                wrapperClass={classes.input__wrapper}
                fullWidth
              />
              <TextInput
                name="country"
                placeholder="Filter by country"
                className={classes.input}
                wrapperClass={classes.input__wrapper}
                fullWidth
              />
              <TextInput
                name="currency"
                placeholder="Filter by currency"
                className={classes.input}
                wrapperClass={classes.input__wrapper}
                fullWidth
              />
            </Stack>
          </Container>
        )}
        <Box className={clsx(classes.btns, classes._mobile)}>
          <Container>
            <FilterButtons
              onSubmit={handleSubmit(onHandleFilters)}
              onExpandFilters={onExpandFilters}
              isExpanded={isFullyExpanded}
              onReset={onResetFilters}
            />
          </Container>
        </Box>
      </Box>
    </FormProvider>
  );
};
