import Image from "next/image";

import { FC, useMemo } from "react";

import { Tabs } from "@causevest/ui-kit";
import { FeedBlock } from "@components";
import { Box, Stack } from "@mui/material";

import { AboutCreator, Backer, FAQ, Thread } from "@features/campaign";

import { Campaign } from "@lib/types";

import classes from "./CampaignFeed.module.scss";

interface Props {
  campaign: Campaign;
}

export const CampaignFeed: FC<Props> = ({ campaign }) => {
  const tabsList = useMemo(() => {
    const result = ["Overview"];
    const lastItem = campaign.is_subscription ? "About" : "Backers";
    if (campaign.threads?.length) {
      result.push("Threads");
    }

    if (campaign.faq?.questions.length) {
      result.push("FAQ");
    }

    result.push(lastItem);

    return result;
  }, [campaign.faq?.questions.length, campaign.is_subscription, campaign.threads?.length]);

  return (
    <Box className={classes.wrapper}>
      {campaign.is_subscription && !!campaign.image.length && (
        <Box className={classes.creatorPic}>
          <Box className={classes.creatorPic__inner}>
            <Image
              src={campaign.image}
              alt="creator pic"
              style={{ objectFit: "cover" }}
              priority
              sizes="100%"
              fill
            />
          </Box>
        </Box>
      )}
      <Tabs values={tabsList} isFeed />
      <Stack className={classes.feed}>
        <FeedBlock title={campaign.title} index={0}>
          <Box
            className={classes.campaign__description}
            dangerouslySetInnerHTML={{ __html: campaign.description }}
          />
        </FeedBlock>
        {!!campaign.threads?.length && (
          <FeedBlock title="Threads" index={1} sx={{ gap: "25px", pt: "10px", pb: "10px" }}>
            {campaign.threads.map((thread) => (
              <Thread key={thread.id} thread={thread} />
            ))}
          </FeedBlock>
        )}
        {!!campaign.faq?.questions.length && (
          <FeedBlock title="FAQ" index={2}>
            <FAQ faqs={campaign.faq.questions} />
          </FeedBlock>
        )}
        {campaign.is_subscription ? (
          <FeedBlock title="About" index={tabsList.length - 1}>
            <AboutCreator campaign={campaign} />
          </FeedBlock>
        ) : (
          <FeedBlock title="Backers" index={tabsList.length - 1} sx={{ gap: "30px" }}>
            <Backer />
            <Backer />
            <Backer />
          </FeedBlock>
        )}
      </Stack>
    </Box>
  );
};
