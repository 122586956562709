"use client";

import { FC, useState } from "react";

import {
  GradientButton,
  Icon,
  InfoBlock,
  SecondaryButton,
  useElementOpen,
} from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";

import { Tier, User } from "@lib/types/models";

import { TiersListModal } from "../TiersListModal";
import classes from "./DonorView.module.scss";
import { SupportersSlider } from "./SupportersSlider";

interface Props {
  tiers: Tier[];
  owner: Partial<User>;
}

export const SubscriptionAside: FC<Props> = ({ tiers, owner }) => {
  const { open, close, isOpen } = useElementOpen(false);
  const [currentTier, setCurrentTier] = useState(0);

  const openCurrentTier = (idx: number) => {
    setCurrentTier(idx);
    open();
  };

  return (
    <>
      <Stack className={classes.aside}>
        <GradientButton className={classes.supportBtn} onClick={open}>
          Support
        </GradientButton>
        {tiers?.map((tier, key) => (
          <InfoBlock key={`tier_${key}`} title={tier.title} innerClass={classes.tier}>
            <Icon
              src={tier.tier_image?.length ? tier.tier_image : "/images/logo-short.svg"}
              alt="tier reward"
              style={{
                objectFit: tier.tier_image?.length ? "cover" : "contain",
                inset: tier.tier_image?.length ? 0 : "0 8px",
              }}
              className={classes.tier__image}
              fill
              sizes="100%"
              priority
            />
            <Typography variant="h2" className={classes.tier__name}>
              {tier.name}
            </Typography>
            <>
              {!!tier.reward_description && (
                <Typography className={classes.tier__description}>
                  {tier.reward_description}
                </Typography>
              )}
            </>
            <SecondaryButton
              className={classes.tier__donateBtn}
              onClick={() => openCurrentTier(key)}
            >
              {`$${tier.amount} per month`}
            </SecondaryButton>
          </InfoBlock>
        ))}
        <SupportersSlider />
      </Stack>
      <TiersListModal
        isOpen={isOpen}
        onClose={close}
        tiers={tiers}
        current={currentTier}
        owner={owner}
      />
    </>
  );
};
