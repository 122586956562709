import { FC } from "react";

import { GradientButton, Icon, ModalActionsField } from "@causevest/ui-kit";
import { Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

import { useSession } from "@contexts";

import classes from "./CampaignCreationWelcomeModal.module.scss";

interface Item {
  image: string;
  title: string;
  hint: string;
  choice: boolean;
}

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (choice: boolean) => void;
  onPrev?: () => void;
  title: string;
  items: readonly Item[];
}

export const CampaignCreationWelcomeModal: FC<Props> = ({
  isOpen,
  handleClose,
  onSubmit,
  onPrev,
  title,
  items,
}) => {
  const { user } = useSession();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Typography className={classes.title}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack className={classes.inner}>
          {items.map((item) => (
            <Stack key={item.title} className={classes.item}>
              <Icon
                src={item.image}
                alt="first choice img"
                width={180}
                height={160}
                className={classes.img}
                priority
              />
              <GradientButton onClick={() => onSubmit(item.choice)} className={classes.submitBtn}>
                {item.title}
              </GradientButton>
              <Typography className={classes.hint}>{item.hint}</Typography>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <ModalActionsField
        onClose={handleClose}
        onPrev={user?.has_subscription ? undefined : onPrev}
      />
    </Dialog>
  );
};
