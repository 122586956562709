"use client";

import { FC } from "react";

import { GradientButton, InfoBlock, useElementOpen } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";

import { AuditTrailPopup } from "@features/campaign";

import { Backer } from "../Backer";
import classes from "./Activity.module.scss";

export const Activity: FC = () => {
  const { isOpen, open, close } = useElementOpen(false);

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        Activity
      </Typography>
      <InfoBlock>
        <Stack gap="25px">
          <Backer isShort />
          <Backer isShort />
          <Backer isShort />
        </Stack>
      </InfoBlock>
      <GradientButton className={classes.btn} onClick={open}>
        View Audit Trail
      </GradientButton>
      <AuditTrailPopup isOpen={isOpen} onClose={close} />
    </>
  );
};
