import React, { FC } from "react";

import { ModalActionsField, MuiDialog } from "@causevest/ui-kit";
import { LoggedInSection, ShareSection } from "@components";
import { Stack, Typography } from "@mui/material";

import { useDonationContext } from "@features/donation/DonationContext";

import { useSession } from "@contexts";

import { Campaign } from "@lib/types";

import DonationModalAside from "./DonationModalAside";
import classes from "./StepModal.module.scss";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  campaign: Partial<Campaign>;
}

export const StepThreeModal: FC<Props> = ({ isOpen, handleClose, campaign }) => {
  const { user } = useSession();
  const { clearAnonymousUserData, ...anonymousUserData } = useDonationContext();

  const onCloseModal = () => {
    handleClose();
    clearAnonymousUserData();
  };

  return (
    <MuiDialog
      isOpen={isOpen}
      handleClose={onCloseModal}
      title="Thanks!"
      titleActionBlock={<LoggedInSection onClose={onCloseModal} />}
      bottomContent={
        <ModalActionsField onClose={onCloseModal} onSubmit={onCloseModal} submitBtnText="Finish" />
      }
    >
      <Stack className={classes.wrapper}>
        <div className={classes.form}>
          <div className={classes.greetings}>
            <p className={classes.greetings__description}>
              {`Thanks ${user?.display_name}!`} <br />
              Share to help the campaign achieve their goal!
            </p>
            <ShareSection />
          </div>
          <div className={classes.info}>
            <Typography variant="h4" className={classes.info__title}>
              Donation Details
            </Typography>
            <Stack className={classes.info__inner}>
              <Typography>
                {user?.display_name ??
                  `${anonymousUserData.firstName} ${anonymousUserData.lastName}`.trim()}
              </Typography>
              {user?.address?.country?.name ||
                (anonymousUserData.country && (
                  <Stack flexDirection="row" gap="20px">
                    <Typography>
                      {user?.address?.country?.name ?? anonymousUserData.country}
                    </Typography>
                    <Typography>
                      {user?.address?.postal_code ?? anonymousUserData.postalCode}
                    </Typography>
                  </Stack>
                ))}
              {user?.email ||
                (anonymousUserData.email && (
                  <Typography>{user?.email ?? anonymousUserData.email}</Typography>
                ))}
              {!!anonymousUserData.amount && (
                <Typography>{`Total: ${anonymousUserData.currency ?? "$"}${anonymousUserData.amount}`}</Typography>
              )}
              {/*<SecondaryButton variant="outlined" className={classes.info__btn}>
                Reciept
              </SecondaryButton>*/}
            </Stack>
          </div>
        </div>
        <DonationModalAside campaign={campaign} />
      </Stack>
    </MuiDialog>
  );
};
