import { memo } from "react";

import { Icon } from "@causevest/ui-kit";
import { EntityCard } from "@components";
import { Stack, Typography } from "@mui/material";

import { Campaign } from "@lib/types";

import classes from "./StepModal.module.scss";

interface Props {
  campaign: Partial<Campaign>;
}

function DonationModalAside({ campaign }: Props) {
  return (
    <Stack className={classes.aside}>
      <Stack className={classes.aside__campaign}>
        <Typography variant="h2" className={classes.aside__title}>
          Campaign
        </Typography>
        <EntityCard campaign={campaign as Campaign} />
      </Stack>
      {!!campaign?.fundraiser && (
        <Stack gap="5px">
          <Typography variant="h2" className={classes.aside__title}>
            Beneficiary
          </Typography>
          <Stack flexDirection="row" alignItems="center" gap="10px">
            <Icon
              width={37}
              height={37}
              src={
                campaign.fundraiser.image?.length
                  ? campaign.fundraiser.image
                  : "/images/logo-short.svg"
              }
              alt="fundraiser"
            />
            <Stack>
              <Typography variant="h5" className={classes.aside__creator}>
                {campaign.fundraiser.display_name}
              </Typography>
              {!!campaign.fundraiser.address?.country && (
                <Typography className={classes.aside__country}>
                  {campaign.fundraiser.address.country.name}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
      {!!campaign?.owner && (
        <Stack gap="5px">
          <Typography variant="h2" className={classes.aside__title}>
            Creator
          </Typography>
          <Stack flexDirection="row" alignItems="center" gap="5px">
            <Icon
              width={37}
              height={37}
              src={campaign.owner.image?.length ? campaign.owner.image : "/images/logo-short.svg"}
              alt="fundraiser"
            />
            <Stack>
              <Typography variant="h5" className={classes.aside__creator}>
                {campaign.owner.display_name}
              </Typography>
              {!!campaign.owner.address?.country && (
                <Typography className={classes.aside__country}>
                  {campaign.owner.address.country.name}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default memo(DonationModalAside);
