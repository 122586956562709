import { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { Box, Button, Stack, Typography } from "@mui/material";

import classes from "./Backer.module.scss";

interface Props {
  isShort?: boolean;
}

export const Backer: FC<Props> = ({ isShort }) => (
  <Stack
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    className={classes.wrapper}
  >
    <Stack flexDirection="row" alignItems="center" className={classes.person}>
      <Icon
        src="/images/commentor-2.png"
        width={37}
        height={37}
        alt="commentor"
        className={classes.person__icon}
      />
      <Box>
        <Typography className={classes.person__name}>Tia T.</Typography>
        <Typography className={classes.person__time}>5 days</Typography>
      </Box>
    </Stack>
    {!isShort && (
      <Typography className={classes.description}>
        {`"Shout out to the Americares team currently helping in Nairobi!"`}
      </Typography>
    )}
    <Stack alignItems="flex-end" className={classes.info}>
      <Typography className={classes.info__price}>$200.00</Typography>
      <Stack flexDirection="row" alignItems="center" className={classes.info__votes}>
        <Typography className={classes.info__likes}>50</Typography>
        <Icon
          src="/images/heart-filled-red.svg"
          width={11}
          height={9}
          alt="liked"
          className={classes.info__heart}
        />
        <Button variant="text" className={classes.info__votesLink} disableRipple>
          XCV Votes
        </Button>
      </Stack>
    </Stack>
  </Stack>
);
